import React from "react";
import styled from "styled-components";

import { Badge, Typography } from "@mui/material";
import useAuth from "../../hooks/useAuth";

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

function NavbarUserDisplay() {
  const { user } = useAuth();

  return (
    <React.Fragment>
      <Indicator>
        <Typography variant="h6">{user?.name}</Typography>
      </Indicator>
    </React.Fragment>
  );
}

export default NavbarUserDisplay;
