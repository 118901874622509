import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import NumberFormat from "react-number-format";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import { GeneralSection, TncSection } from "../../types/general";
import MemberTierDetailForm from "./MemberTierForm/MemberTierDetailForm";
import { CKEditorWrapper } from "../../components/form/CKEditorWrapper";
import { forwardRef } from "react";
import { MemberTierDetailTypeEnum } from "./memberTier.enum";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type Item = { tc: string; en: string };

export type OfferDetail = {
  iconId: MemberTierDetailTypeEnum;
  tierClass: number;
  subtitleTc: string;
  subtitleEn: string;
  descriptionTc?: string;
  descriptionEn?: string;
};

export type EditTierValues = {
  id?: number;
  tierName: string;
  spending: number;
  memberTierCode: number;
  welcomeOffersDetail: OfferDetail[];
  birthdayMonthOffersDetail?: OfferDetail[];
  exclusiveOffersDetail?: OfferDetail[];
  allYearRoundOffersDetail?: OfferDetail[];
  monthlyExclusiveOffersDetail?: OfferDetail[];
};

type EditMemberTierFormProps = {
  initialValues: EditTierValues;
  handleSubmit: (values: EditTierValues) => Promise<void>;
  submitError?: string;
};

const validationSchema = Yup.object().shape({
  memberTierCode: Yup.number().required("Required"),
  spending: Yup.number().required("Required"),
  welcomeOffersDetail: Yup.array()
    .of(
      Yup.object()
        .shape({
          iconId: Yup.string().required("Required"),
          subtitleTc: Yup.string().required("Required"),
          subtitleEn: Yup.string().required("Required"),
          descriptionTc: Yup.string().nullable(),
          descriptionEn: Yup.string().nullable(),
        })
        .required("Required")
    )
    .required("Required"),
  birthdayMonthOffersDetail: Yup.array()
    .of(
      Yup.object().shape({
        iconId: Yup.string().required("Required"),
        subtitleTc: Yup.string().required("Required"),
        subtitleEn: Yup.string().required("Required"),
        descriptionTc: Yup.string().nullable(),
        descriptionEn: Yup.string().nullable(),
      })
    )
    .nullable()
    .nullable(),
  exclusiveOffersDetail: Yup.array()
    .of(
      Yup.object().shape({
        iconId: Yup.string().required("Required"),
        subtitleTc: Yup.string().required("Required"),
        subtitleEn: Yup.string().required("Required"),
        descriptionTc: Yup.string().nullable(),
        descriptionEn: Yup.string().nullable(),
      })
    )
    .nullable()
    .nullable(),
  allYearRoundOffersDetail: Yup.array()
    .of(
      Yup.object().shape({
        iconId: Yup.string().required("Required"),
        subtitleTc: Yup.string().required("Required"),
        subtitleEn: Yup.string().required("Required"),
        descriptionTc: Yup.string().nullable(),
        descriptionEn: Yup.string().nullable(),
      })
    )
    .nullable()
    .nullable(),
  monthlyExclusiveOffersDetail: Yup.array()
    .of(
      Yup.object().shape({
        iconId: Yup.string().required("Required"),
        subtitleTc: Yup.string().required("Required"),
        subtitleEn: Yup.string().required("Required"),
        descriptionTc: Yup.string().nullable(),
        descriptionEn: Yup.string().nullable(),
      })
    )
    .nullable()
    .nullable(),
});

export function EditMemberTierForm({
  initialValues,
  handleSubmit,
  submitError,
}: EditMemberTierFormProps) {
  const { t } = useTranslation();
  const isUpdate =
    typeof initialValues?.id !== "undefined" && initialValues?.id !== null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  {values.tierName}
                </Typography>

                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="memberTierCode"
                      onChange={({ target }) =>
                        setFieldValue(
                          "memberTierCode",
                          parseInt(target.value || 0)
                        )
                      }
                      label={t("page.memberTier.form.memberTierCode")}
                      multiline={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      onChange={({ target }) =>
                        setFieldValue("spending", parseInt(target.value || 0))
                      }
                      name="spending"
                      label={t("page.memberTier.form.spending")}
                      InputProps={{
                        startAdornment: "$",
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <MemberTierDetailForm />

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {isUpdate ? t("Update") : t("Create")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/member-tier" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default EditMemberTierForm;
