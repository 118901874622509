import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
} from "@mui/material";

import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import { ImageField } from "../../components/form/ImageField";
import SwitchWrapper from "../../components/form/SwitchWrapper";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import moment from "moment";
import DateTimePickerWrapper from "../../components/form/DatePickerWrapper";
import { useMemo } from "react";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type AboutCovaValues = {
  id?: number;
  titleTc: string;
  titleEn: string;
  detailTc: string;
  detailEn: string;
  status: number;
};

export const initialValues: AboutCovaValues = {
  titleTc: "",
  titleEn: "",
  detailTc: "",
  detailEn: "",
  status: 0,
};

type EditAboutCovaFormProps = {
  values?: AboutCovaValues;
  handleSubmit: (values: AboutCovaValues) => Promise<void>;
  submitError?: string;
};

export function AboutCovaForm({
  values,
  handleSubmit,
  submitError,
}: EditAboutCovaFormProps) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    titleTc: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    titleEn: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    detailTc: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    detailEn: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
  });

  return (
    <Formik
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <SwitchWrapper name="status" label={t("Status")} />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="titleTc"
                        label={t("page.aboutCova.form.titleTc")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="titleEn"
                        label={t("page.aboutCova.form.titleEn")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="detailTc"
                        label={t("page.aboutCova.form.detailTc")}
                        multiline={true}
                        minRows={5}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="detailEn"
                        label={t("page.aboutCova.form.detailEn")}
                        multiline={true}
                        minRows={5}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {t("form.save")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/about-cova" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default AboutCovaForm;
