import { useMemo, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { ErrorMessage, Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  Alert as MuiAlert,
  Autocomplete,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import SwitchWrapper from "../../components/form/SwitchWrapper";
import DatePickerWrapper from "../../components/form/DatePickerWrapper";
import moment from "moment";
import { useAxioses } from "../../utils/useAxios";
import { errorStyle } from "../../components/form/CKEditorWrapper";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type EditBranchValues = {
  id?: number;
  districtId: number;
  titleTc: string;
  titleEn: string;
  branchCode: string;
  phoneNumber: string;
  addressTc: string;
  addressEn: string;
  status: number;
  cakeOrderStatus: number;
  dineInStatus: number;
  startDate?: Date | "";
  endDate?: Date | "";
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};

const initialValues: EditBranchValues = {
  districtId: -1,
  titleTc: "",
  titleEn: "",
  branchCode: "",
  phoneNumber: "",
  addressTc: "",
  addressEn: "",
  status: 0,
  cakeOrderStatus: 0,
  dineInStatus: 0,
};

type BranchFormProps = {
  values?: EditBranchValues;
  handleSubmit: (values: EditBranchValues) => Promise<void>;
  submitError?: string;
};

export function BranchForm({
  values,
  handleSubmit,
  submitError,
}: BranchFormProps) {
  const { t } = useTranslation();
  const today = moment().startOf("day").toDate();

  const { response } = useAxioses([
    {
      method: "GET",
      url: `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/district`,
    },
  ]);

  const districtList = response?.[0]?.[0] ?? [];

  const validationSchema = Yup.object().shape(
    {
      titleTc: Yup.string().required("Required"),
      titleEn: Yup.string().required("Required"),
      branchCode: Yup.string().required("Required"),
      districtId: Yup.number()
        .min(0, "District is required")
        .required("Required"),
      addressTc: Yup.string().required("Required"),
      addressEn: Yup.string().required("Required"),
      phoneNumber: Yup.string()
        .matches(/^[2-9][0-9]{7}$/, "Invalid phone number")
        .length(8, "Telephone number should be in 8 digit number format")
        .required("Required"),
      cakeOrderStatus: Yup.number().when("dineInStatus", {
        is: 0,
        then: Yup.number().oneOf([1], "At least one is required"),
        otherwise: Yup.number(),
      }),
      dineInStatus: Yup.number().when("cakeOrderStatus", {
        is: 0,
        then: Yup.number().oneOf([1], "At least one is required"),
        otherwise: Yup.number(),
      }),
    },
    [["dineInStatus", "cakeOrderStatus"]]
  );

  return (
    <Formik
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        values,
        errors,
        setFieldValue,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                {t("page.branch.subtitle")}
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={4} lg={3}>
                  <SwitchWrapper
                    name="status"
                    label={t("page.branch.form.status")}
                  />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <SwitchWrapper
                    name="cakeOrderStatus"
                    label={t("page.branch.form.cakeOrderStatus")}
                  />
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                  <SwitchWrapper
                    name="dineInStatus"
                    label={t("page.branch.form.dineInStatus")}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="titleEn"
                      label={t("page.branch.form.nameEn")}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="titleTc"
                      label={t("page.branch.form.nameTc")}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="addressTc"
                      label={t("page.branch.form.addressTc")}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="addressEn"
                      label={t("page.branch.form.addressEn")}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <TextFieldWrapper
                      name="branchCode"
                      label={t("page.branch.form.branchCode")}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <TextFieldWrapper
                      name="districtId"
                      label={t("page.branch.form.branchDistrict")}
                      select
                      onChange={(e) => {
                        console.log(e.target.value);
                        handleChange(e);
                      }}
                    >
                      <MenuItem key={-1} value={-1} disabled>
                        -- {t("Please Select")} --
                      </MenuItem>
                      {districtList &&
                        districtList.map((district) => (
                          <MenuItem key={district.id} value={district.id}>
                            {district.nameEn}
                          </MenuItem>
                        ))}
                    </TextFieldWrapper>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3}>
                    <TextFieldWrapper
                      name="phoneNumber"
                      label={t("page.branch.form.phoneNumber")}
                      onChange={(e) => {
                        const numberRegex = /^[0-9\b]+$/;

                        if (
                          e.target.value === "" ||
                          numberRegex.test(e.target.value)
                        ) {
                          handleChange(e);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {submitError && (
            <Alert mt={2} mb={3} severity="warning">
              {submitError}
            </Alert>
          )}

          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="primary"
            mr={1}
          >
            {t("form.save")}
          </LoadingButton>
          <CancelButtonWithDialog backUrl="/branch-management" />
        </form>
      )}
    </Formik>
  );
}

export default BranchForm;
