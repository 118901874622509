import { useEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useAxioses } from "../../utils/useAxios";
import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import { Ability } from "@casl/ability";
import convertAbility from "./convertAbility";
import EditUserRoleForm, { EditUserRoleFormValues } from "./EditUserRoleForm";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);

function EditUserRolePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userRoleId } = useParams();

  const {
    response: initResponse,
    isError: isInitError,
    isLoading: isInitLoading,
    error: initError,
  } = useAxioses([
    {
      method: "GET",
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/user-roles/${userRoleId}`,
    },
  ]);

  const userRoleResponse = initResponse?.[0];

  const userRole = useMemo(() => {
    if (userRoleResponse) {
      const { ...newUserRole }: any = userRoleResponse;

      const roleAbility = new Ability(userRoleResponse.permission);
      newUserRole.permission = convertAbility(roleAbility);

      return newUserRole;
    }
  }, [userRoleResponse]);

  const isSuperUser = userRole && userRole.id === 0;

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "PATCH",
    `${process.env.REACT_APP_USER_SERVICE_URL}/user-roles/${userRoleId}`
  );

  const handleSubmit = async (values: EditUserRoleFormValues) => {
    let newUserRole: any = { ...values };
    delete newUserRole.id;
    delete newUserRole.createdAt;
    delete newUserRole.updatedAt;
    delete newUserRole.deletedAt;
    await submit({ data: newUserRole });
  };

  // Redirect when create success
  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/role-permission"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("Edit User Role")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("Edit User Role")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/role-permission">
          {t("page.userRole.title")}
        </Link>
        <Typography>{t("Edit User Role")}</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      {isSubmitSuccess ? (
        <Card mb={6}>
          <CardContent>
            <Alert severity="success" my={3}>
              {t("Updated User Role Successfully")}
            </Alert>
          </CardContent>
        </Card>
      ) : isInitError ? (
        <Alert severity="warning" my={3}>
          {initError}
        </Alert>
      ) : isInitLoading ? (
        <LinearProgress />
      ) : (
        <EditUserRoleForm
          initialValues={userRole}
          handleSubmit={handleSubmit}
          submitError={submitError}
          isSuperUser={isSuperUser}
        />
      )}
    </>
  );
}

export default EditUserRolePage;
