import styled from "styled-components/macro";
import {
  Typography,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent as MuiCardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColumns,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import useAuth from "../../hooks/useAuth";
import { Can } from "@casl/react";
import { Ability } from "@casl/ability";
import { useAxios } from "../../utils/useAxios";
import { useState } from "react";
import GridToolbar from "../../components/datagrid/GridToolBar";
import _ from "lodash";
import { GeneralSection } from "../../types/general";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

const rows = [
  {
    name: "page.general.tnc.title.registration",
    editPath: "/general-management/registration-tnc/edit/",
  },
  {
    name: "page.general.tnc.title.general",
    editPath: "/general-management/tnc/edit/",
  },
  {
    name: "page.general.privacy.title",
    editPath: "/general-management/privacy-policy/edit/",
  },
  {
    name: "page.general.license.title",
    editPath: "/general-management/license-policy/edit/",
  },
  {
    name: "page.general.tnc.title.deactivation",
    editPath: "/general-management/deactivation-tnc/edit/",
  },
];

const GeneralPage = () => {
  const { userAbility } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const Button = styled(MuiButton)(spacing);

  return (
    <>
      <Helmet title={t("page.general.title")} />
      <Grid item md={2}>
        <Typography
          variant="h3"
          gutterBottom
          display="inline"
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("page.general.title")}
        </Typography>
      </Grid>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Typography>{t("page.general.title")}</Typography>
      </Breadcrumbs>
      <Divider my={2} />

      <Card mb={6}>
        <CardContent>
          {rows.map((row, index) => {
            return (
              <Grid
                key={index}
                container
                spacing={2}
                alignItems="center"
                margin="4px"
              >
                <Grid item xs={6} md={4} lg={2}>
                  {t(row.name)}
                </Grid>
                <Grid item xs={6}>
                  <Can
                    do="updateAll"
                    on="CmsGeneralManagement"
                    field="GeneralSetting"
                    ability={(userAbility as any) ?? new Ability()}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      mr={1}
                      onClick={() => navigate(row.editPath)}
                    >
                      {t("Edit")}
                    </Button>
                  </Can>
                </Grid>
              </Grid>
            );
          })}
        </CardContent>
      </Card>
    </>
  );
};

export default GeneralPage;
