import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

export type langModel = "en";

export const langList: langModel[] = ["en"];

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.REACT_APP_PUBLIC_URL}/locales/{{lng}}/translation.json`,
    },
    fallbackLng: langList[0], // * default en
    supportedLngs: langList,
    preload: langList,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      wait: false,
    },
  });

const language = localStorage.getItem("language");

if (language) {
  i18n.changeLanguage(language);
}
