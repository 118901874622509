import { Ability } from "@casl/ability";

import { cmsFunctionList } from "./PermissionMatrix";

function convertAbility(ability: Ability) {
  let matrixValues = {};
  cmsFunctionList.forEach((section) => {
    matrixValues[section.subject] = {};
    section.functions.forEach((cmsFunction) => {
      matrixValues[section.subject][cmsFunction.field] = {};
      Object.keys(cmsFunction.enabled).forEach((action) => {
        matrixValues[section.subject][cmsFunction.field][action] = ability.can(
          action,
          section.subject,
          cmsFunction.field
        );
      });
    });
  });

  return matrixValues;
}

export default convertAbility;
