import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
} from "@mui/material";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import { ImageField } from "../../components/form/ImageField";
import SwitchWrapper from "../../components/form/SwitchWrapper";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import moment from "moment";
import { useMemo } from "react";
import DateTimePickerWrapper from "../../components/form/DateTimePickerWrapper";
import { DeepLinkIdList, DeepLinkType, LinkType } from "./news.enum";
import DatePickerWrapper from "../../components/form/DatePickerWrapper";
import { urlRegExp } from "../../regex";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type NewsValues = {
  id?: number;
  titleTc: string;
  titleEn: string;
  subtitleTc: string;
  subtitleEn: string;
  contentTc: string;
  contentEn: string;
  status: number;
  linkType?: LinkType;
  link?: string;
  deepLink?: DeepLinkType;
  deepLinkId?: string;
  from: Date | "";
  to: Date | "";
  image: File | null;
  imageUrl?: string;
};

export const initialValues: NewsValues = {
  titleTc: "",
  titleEn: "",
  subtitleTc: "",
  subtitleEn: "",
  contentTc: "",
  contentEn: "",
  status: 0,
  linkType: LinkType.NONE,
  link: "",
  deepLink: DeepLinkType.BRANCH,
  deepLinkId: "",
  image: null,
  from: "",
  to: "",
};

type EditNewsFormProps = {
  values?: NewsValues;
  handleSubmit: (values: NewsValues) => Promise<void>;
  submitError?: string;
};

export function NewsForm({
  values,
  handleSubmit,
  submitError,
}: EditNewsFormProps) {
  const { t } = useTranslation();
  const today = moment().startOf("day").toDate();
  const MAX_FILE_SIZE = 5;

  const isUpdate = useMemo(() => {
    return values?.id !== null && values?.id !== undefined;
  }, [values?.id]);

  const validationSchema = Yup.object().shape(
    {
      titleTc: Yup.string()
        .required("Required")
        .max(255, "Maximum 255 characters"),
      titleEn: Yup.string()
        .required("Required")
        .max(255, "Maximum 255 characters"),
      subtitleTc: Yup.string()
        .required("Required")
        .max(255, "Maximum 255 characters"),
      subtitleEn: Yup.string()
        .required("Required")
        .max(255, "Maximum 255 characters"),
      contentTc: Yup.string()
        .required("Required")
        .max(255, "Maximum 255 characters"),
      contentEn: Yup.string()
        .required("Required")
        .max(255, "Maximum 255 characters"),
      image: Yup.mixed()
        .required("Required")
        .test("maxSize", t("form.error.invalidFileSize"), (value) =>
          typeof value === "string"
            ? true
            : value?.size < MAX_FILE_SIZE * 1000 * 1000
        ),
      linkType: Yup.string().nullable(),
      link: Yup.string().when("linkType", {
        is: (linkType) => linkType === LinkType.URI,
        then: Yup.string()
          .required("Required")
          .nullable()
          .url(t("form.error.invalidUrl"))
          .matches(urlRegExp, t("form.error.invalidUrl")),
        otherwise: Yup.string().nullable(),
      }),
      deepLink: Yup.string().when(["linkType"], {
        is: (linkType) => linkType === LinkType.DEEP_LINK,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().nullable(),
      }),
      deepLinkId: Yup.string().when(["deepLink", "linkType"], {
        is: (deepLink, linkType) =>
          linkType === LinkType.DEEP_LINK && DeepLinkIdList.includes(deepLink),
        then: Yup.string().required("Required").nullable(),
        otherwise: Yup.string().nullable(),
      }),
      from: Yup.date().required("Required"),
      to: Yup.date()
        .nullable()
        .when(
          "from",
          (from, schema) =>
            from && schema.min(from, "Must be greater then the date in from")
        ),
    },
    [["from", "to"]]
  );

  const linkTypeList = [
    {
      label: t("page.news.form.none"),
      value: LinkType.NONE,
    },

    {
      label: t("page.news.form.externalWebLink"),
      value: LinkType.URI,
    },
    {
      label: t("page.news.form.deepLink.title"),
      value: LinkType.DEEP_LINK,
    },
  ];

  const deepLinkTypeList = [
    {
      label: t("page.news.form.deepLink.profile"),
      value: DeepLinkType.PROFILE,
    },
    {
      label: t("page.news.form.deepLink.qrCode"),
      value: DeepLinkType.QR_CODE,
    },
    {
      label: t("page.news.form.deepLink.memberTier"),
      value: DeepLinkType.MEMBER_TIER,
    },
    {
      label: t("page.news.form.deepLink.news"),
      value: DeepLinkType.NEWS_ID,
    },
    {
      label: t("page.news.form.deepLink.branch"),
      value: DeepLinkType.BRANCH,
    },
    {
      label: t("page.news.form.deepLink.reward"),
      value: DeepLinkType.REWARD,
    },
    {
      label: t("page.news.form.deepLink.faq"),
      value: DeepLinkType.FAQ,
    },
    {
      label: t("page.news.form.deepLink.inbox_personal"),
      value: DeepLinkType.INBOX_PERSONAL,
    },
    {
      label: t("page.news.form.deepLink.inbox_promotional"),
      value: DeepLinkType.INBOX_PROMOTIONAL,
    },
    {
      label: t("page.news.form.deepLink.ordering_site"),
      value: DeepLinkType.ORDERING_SITE,
    },
  ];

  return (
    <Formik
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <SwitchWrapper name="status" label={t("Status")} />
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper disabled name="id" label={t("ID")} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="titleTc"
                        label={t("page.news.form.titleTc")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="titleEn"
                        label={t("page.news.form.titleEn")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="subtitleTc"
                        label={t("page.news.form.subtitleTc")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="subtitleEn"
                        label={t("page.news.form.subtitleEn")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="contentTc"
                        label={t("page.news.form.detailTc")}
                        minRows={3}
                        multiline
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="contentEn"
                        label={t("page.news.form.detailEn")}
                        minRows={3}
                        multiline
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={2}>
                      <TextFieldWrapper
                        name="linkType"
                        label={t("page.news.form.link")}
                        value={values.linkType}
                        select
                      >
                        {linkTypeList.map((type, index) => (
                          <MenuItem key={index} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </TextFieldWrapper>
                    </Grid>

                    {values.linkType === LinkType.URI && (
                      <Grid item xs={12} md={8} lg={10}>
                        <TextFieldWrapper
                          name="link"
                          label={t("page.news.form.externalLink")}
                          onChange={(e) => {
                            setFieldValue(
                              "link",
                              e.target.value.replaceAll(" ", "")
                            );
                          }}
                        />
                      </Grid>
                    )}

                    {values.linkType === LinkType.DEEP_LINK && (
                      <Grid item xs={12} md={4} lg={2}>
                        <TextFieldWrapper
                          name="deepLink"
                          label={t("page.news.form.deepLink.title")}
                          value={values.deepLink}
                          select
                        >
                          {deepLinkTypeList.map((type, index) => (
                            <MenuItem key={index} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </TextFieldWrapper>
                      </Grid>
                    )}

                    {values.linkType === LinkType.DEEP_LINK &&
                      values.deepLink &&
                      DeepLinkIdList.includes(values.deepLink) && (
                        <Grid item xs={12} md={8} lg={6}>
                          <TextFieldWrapper
                            name="deepLinkId"
                            label={t("page.news.form.deepLinkId")}
                            onChange={(e) => {
                              setFieldValue(
                                "deepLinkId",
                                e.target.value.replaceAll(" ", "")
                              );
                            }}
                          />
                        </Grid>
                      )}
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <DatePickerWrapper
                        name="from"
                        label={t("form.from")}
                        value={values.from}
                        setStartOfDate={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={3}>
                      <DatePickerWrapper
                        name="to"
                        label={t("form.to")}
                        value={values.to}
                        minDate={values.from ? new Date(values.from) : today}
                        setEndOfDate={true}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <ImageField
                      fieldName="image"
                      header={t("page.news.form.image")}
                      buttonText="UPLOAD NEWS"
                      remarks={[
                        "Size: width 1140 x height 728",
                        "Supported format: png, jpg",
                        `Max. file size: ${MAX_FILE_SIZE}MB`,
                      ]}
                      isUpdate={isUpdate}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {t("form.save")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/news" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default NewsForm;
