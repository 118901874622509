import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  LinearProgress,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import { useAxios } from "../../utils/useAxios";
import EditUserForm, { EditUserFormValues } from "./EditUserForm";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Alert = styled(MuiAlert)(spacing);

const initialValues: EditUserFormValues = {
  name: "",
  email: "",
  userRoleId: -1,
  password: "",
  confirmPassword: "",
  status: 1,
};

function CreateUserPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    response: initResponse,
    isError: isInitError,
    isLoading: isInitLoading,
    error: initError,
  } = useAxios(
    "GET",
    `${process.env.REACT_APP_USER_SERVICE_URL}/user-roles?take=0&skip=0`
  );
  const userRoles = initResponse?.[0];

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_USER_SERVICE_URL}/users`
  );

  //Submit button
  const handleSubmit = async (values: EditUserFormValues) => {
    const { confirmPassword, ...body } = values; // Remove confirmPassword and submit from values

    await submit({ data: body as Record<string, any> });
  };

  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/accounts"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("Create User")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("Create User")}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/accounts">
          {t("Accounts")}
        </Link>
        <Typography>{t("Create User")}</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      {isInitLoading ? (
        <LinearProgress />
      ) : isInitError ? (
        <Alert severity="warning" my={3}>
          {initError}
        </Alert>
      ) : isSubmitSuccess ? (
        <Alert severity="success" my={3}>
          {t("Created New User Successfully")}
        </Alert>
      ) : (
        <EditUserForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          submitError={submitError}
          userRoles={userRoles}
        />
      )}
    </>
  );
}

export default CreateUserPage;
