import React, { useState } from "react";
import styled from "styled-components/macro";

import {
  CardContent,
  Grid,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/useAxios";

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)<{ m?: number }>(spacing);

interface SearchBarProps {
  columns: any;
  where: Record<string, string | number | undefined>;
  setWhere: React.Dispatch<
    React.SetStateAction<Record<string, string | number | undefined>>
  >;
  fetchData: () => void;
}

function FAQSearchBar({ setWhere }: SearchBarProps) {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const [faqCategoryId, setFaqCategoryId] = useState<number | undefined>();

  const handleClick = (e: any) => {
    setWhere({ keyword, faqCategoryId });
  };

  const handleSelect = (e: any) => {
    setFaqCategoryId(e.target.value);
  };

  const handleSelectFAQ = (e: any) => {
    setKeyword(e.target.value);
  };

  // const { response } = useAxios(
  //   "GET",
  //   `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/faq/category`
  // );
  // const categoryList = response?.[0] ?? [];

  return (
    <React.Fragment>
      <Card mb={4}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Typography variant="h6" gutterBottom>
            {t("form.keywords")}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleClick(e);
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  value={keyword}
                  size="small"
                  m={1}
                  label={t("page.faq.form.keyword")}
                  fullWidth
                  variant="outlined"
                  name="keyword"
                  onChange={handleSelectFAQ}
                ></TextField>
              </Grid>
              {/* 
              <Grid item xs={6}>
                <TextField
                  size="small"
                  m={1}
                  label={t("page.faq.category.title")}
                  fullWidth
                  select
                  variant="outlined"
                  name="category"
                  value={faqCategoryId}
                  onChange={handleSelect}
                >
                  <MenuItem value={undefined}>-</MenuItem>
                  {categoryList.map((category, index) => (
                    <MenuItem key={index} value={category.id}>
                      {category.titleTc}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid> */}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  mx={1}
                  variant="contained"
                  color="primary"
                >
                  {t("Search")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default FAQSearchBar;
