export enum GeneralSection {
  CONTACT = "CONTACT",
  TNC = "TNC",
  TUTORIAL = "TUTORIAL",
  SHARING_CONTENT = "SHARING_CONTENT",
  HISTORY_REMARK = "HISTORY_REMARK",
  FRIEND_GET_FRIEND = "FRIEND_GET_FRIEND",
  REGISTRATION_TNC = "REGISTRATION_TNC",
  DEACTIVATION_TNC = "DEACTIVATION_TNC",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  LICENSE_PERMIT = "LICENSE_PERMIT",
}

export type TncSection =
  | GeneralSection.TNC
  | GeneralSection.REGISTRATION_TNC
  | GeneralSection.DEACTIVATION_TNC;
