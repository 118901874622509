import React from "react";
import { Field } from "formik";
import {
  TextField as MuiTextField,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Box, spacing } from "@mui/system";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type TextFieldWrapperProps = {
  name: string;
  label: string;
  my?: number;
} & Record<string, any>;

const SwitchWrapper: React.FunctionComponent<TextFieldWrapperProps> = ({
  name,
  label,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            {...props}
          >
            <FormControlLabel
              control={
                <Switch
                  name={name}
                  checked={field.value === 1}
                  onChange={(e) => {
                    const value = e.target.checked;
                    form.setFieldValue(name, value === true ? 1 : 0);
                  }}
                />
              }
              label={t(label)}
              labelPlacement="start"
            />
            {Boolean(meta.touched && meta.error) && (
              <Typography sx={{ margin: "3px" }} color="error">
                {meta.touched && t(meta.error ?? "")}
              </Typography>
            )}
          </Box>
        );
      }}
    </Field>
  );
};

export default SwitchWrapper;
