import { useRef } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
} from "@mui/material";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import { EditFAQValues } from "./FAQ.type";
import { useAxios } from "../../utils/useAxios";
import SwitchWrapper from "../../components/form/SwitchWrapper";
import { CKEditorWrapper } from "../../components/form/CKEditorWrapper";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

type FAQFormProps = {
  initialValues: EditFAQValues;
  handleSubmit: (values: EditFAQValues) => Promise<void>;
  submitError?: string;
};

export function FAQForm({
  initialValues,
  handleSubmit,
  submitError,
}: FAQFormProps) {
  const { t } = useTranslation();
  const editorRef = useRef<any>(null);

  const validationSchema = Yup.object().shape({
    // faqCategoryId: Yup.number().required("Required"),
    titleTc: Yup.string().required("Required"),
    titleEn: Yup.string().required("Required"),
    answerTc: Yup.string().required(t("Required")),
    answerEn: Yup.string().required(t("Required")),
  });

  const { response } = useAxios(
    "GET",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/faq/category`
  );
  const categoryList = response?.[0] ?? [];

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        isSubmitting,
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <SwitchWrapper name="status" label={t("Status")} />
                  </Grid>

                  <Grid item xs={12} sx={{ display: "none" }}>
                    <TextFieldWrapper
                      name="faqCategoryId"
                      label={t("page.faq.form.categoryTitleTc")}
                      select
                      defaultValue={1}
                      onChange={handleChange}
                    >
                      {categoryList.map((category, index) => (
                        <MenuItem key={index} value={category.id}>
                          {category.titleTc}
                        </MenuItem>
                      ))}
                    </TextFieldWrapper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="titleTc"
                      label={t("page.faq.form.titleTc")}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="titleEn"
                      label={t("page.faq.form.titleEn")}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="answerTc"
                      label={t(`page.faq.form.detailTc`)}
                      multiline={true}
                      minRows={5}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name="answerEn"
                      label={t(`page.faq.form.detailEn`)}
                      multiline={true}
                      minRows={5}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {t("form.save")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/faq" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default FAQForm;
