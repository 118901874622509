import { useCallback, useState, useEffect } from "react";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import styled from "styled-components/macro";
import update from "immutability-helper";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { Button, Grid } from "@mui/material";
import CancelButtonWithDialog from "../form/CancelButtonWithDialog";
import { useNavigate } from "react-router-dom";
import CancelButtonWithDialogWithoutFormik from "../form/CancelButtonWithDialogWithoutFormik";

const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

type ContainerProps = {
  cardList: Record<string, any>[];
  handleSubmit: (data: any) => Promise<void>;
  isSubmitting: boolean;
  columns: Record<string, any>[];
  backUrl?: string;
  isChanged?: boolean;
};

export function DndContainer({
  cardList,
  handleSubmit,
  isSubmitting,
  columns,
  backUrl,
  isChanged = true,
}: ContainerProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cards, setCards] = useState(cardList);
  const [displaySequence, setDisplaySequence] = useState<Array<any>>([]);
  const columnHeaders = columns.map((col) => col.field);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Record<string, any>[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Record<string, any>],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card: Record<string, any>, index: number) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        card={card}
        columns={columnHeaders}
        moveCard={moveCard}
      />
    );
  }, []);

  useEffect(() => {
    setCards(cardList);
  }, [cardList]);

  useEffect(() => {
    setDisplaySequence(
      cards.map((card, index) => {
        return { ...card, displaySequence: index + 1 };
      })
    );
  }, [cards]);

  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="row"
        wrap="nowrap"
        marginLeft={4}
      >
        {columns.map((col, index) => (
          <Grid key={index} item width={150} marginRight={1.5}>
            {col.headerName}
          </Grid>
        ))}
      </Grid>
      <Grid my={5}>{cards.map((card, i) => renderCard(card, i))}</Grid>
      <LoadingButton
        loading={isSubmitting}
        variant="contained"
        color="primary"
        mr={1}
        onClick={() => handleSubmit(displaySequence)}
      >
        {t("form.save")}
      </LoadingButton>
      {backUrl && (
        <CancelButtonWithDialogWithoutFormik
          backUrl={backUrl}
          isChanged={isChanged}
        />
      )}
    </>
  );
}
