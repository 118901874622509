export enum MemberTierDetailTypeEnum {
  "CUP" = "CUP",
  "CAKE" = "CAKE",
  "CAKE2" = "CAKE2",
  "DINE" = "DINE",
  "CROWN" = "CROWN",
  "GIFT" = "GIFT",
  "PERCENTAGE" = "PERCENTAGE",
}

type MemberTierDetailIconListType = {
  iconId: MemberTierDetailTypeEnum;
  imagePath: string;
};

export const MemberTierDetailIconList: MemberTierDetailIconListType[] = [
  {
    iconId: MemberTierDetailTypeEnum.CUP,
    imagePath: "/static/img/memberTierIcons/cup.png",
  },
  {
    iconId: MemberTierDetailTypeEnum.CAKE,
    imagePath: "/static/img/memberTierIcons/cake.png",
  },
  {
    iconId: MemberTierDetailTypeEnum.CAKE2,
    imagePath: "/static/img/memberTierIcons/cake2.png",
  },
  {
    iconId: MemberTierDetailTypeEnum.DINE,
    imagePath: "/static/img/memberTierIcons/dine-in.png",
  },
  {
    iconId: MemberTierDetailTypeEnum.CROWN,
    imagePath: "/static/img/memberTierIcons/crown.png",
  },
  {
    iconId: MemberTierDetailTypeEnum.GIFT,
    imagePath: "/static/img/memberTierIcons/gift.png",
  },
  {
    iconId: MemberTierDetailTypeEnum.PERCENTAGE,
    imagePath: "/static/img/memberTierIcons/percentage.png",
  },
];
