export const MEMBER_TIER_TOOLBAR_CONFIG = [
  "heading",
  "fontBackgroundColor",
  "fontColor",
  "bold",
  "italic",
  "underline",
  "strikeThrough",
  "alignment",
  "undo",
  "redo",
];
