import { useState, useCallback, useMemo } from "react";
import { HTTPMethod, useAxiosStatus } from "./useAxios";
import axios from "./axios";

export function useDelayedAxios<UrlType extends string | undefined>(
  method: HTTPMethod,
  url?: UrlType
) {
  const [status, setStatus] = useState<useAxiosStatus>("IDLE");
  const isIdle = useMemo(() => status === "IDLE", [status]);
  const isLoading = useMemo(() => status === "LOADING", [status]);
  const isSuccess = useMemo(() => status === "SUCCESS", [status]);
  const isError = useMemo(() => status === "ERROR", [status]);

  const [response, setResponse] = useState<any>();
  const [error, setError] = useState<string | undefined>();

  const fetch = useCallback(
    async ({
      data,
      fetchURL,
    }: {
      data?: Record<string, unknown> | FormData;
      fetchURL?: UrlType extends string ? undefined : string;
    }) => {
      if (typeof fetchURL === "undefined" && typeof url === "undefined") {
        setError("No URL is provided");
        return;
      }

      setStatus("LOADING");
      setResponse(undefined);
      setError(undefined);

      try {
        const result = await axios({ method, data, url: fetchURL ?? url });
        setResponse(result.data);
        setStatus("SUCCESS");
      } catch (err: any) {
        setStatus("ERROR");
        if (err.message) {
          setError(err.message.toString());
        } else {
          setError(err);
        }
      }
    },
    [method, url, setResponse, setError]
  );

  return {
    fetch,
    status,
    isIdle,
    isLoading,
    isSuccess,
    isError,
    response,
    error,
  };
}
