import React from "react";
import styled from "styled-components/macro";
import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";
import { Field, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { endOfDay, startOfDay } from "date-fns";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type DatePickerWrapperProps = {
  name: string;
  label: string;
  minDat?: Date;
  setStartOfDate?: boolean;
  setEndOfDate?: boolean;
  textFieldProps?: TextFieldProps;
} & Record<string, any>;

const DatePickerWrapper: React.FunctionComponent<DatePickerWrapperProps> = ({
  name,
  label,
  minDate,
  setStartOfDate,
  setEndOfDate,
  textFieldProps,
  ...props
}) => {
  const { t } = useTranslation();

  const handlePreventDefault = (e) => {
    e.preventDefault();
  };

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        return (
          <DatePicker
            {...field}
            minDate={minDate}
            label={t(label)}
            clearable={true}
            clearText="Clear"
            onChange={(newDate: Date | null) => {
              if (setStartOfDate && newDate) newDate = startOfDay(newDate);
              if (setEndOfDate && newDate) newDate = endOfDay(newDate);
              form.setFieldValue(name, newDate);
            }}
            onClose={() => {
              form.setFieldTouched(name);
            }}
            {...props}
            renderInput={(params) => {
              return (
                <TextField
                  name={name}
                  {...params}
                  size="small"
                  fullWidth
                  error={Boolean(meta.touched && meta.error)}
                  helperText={meta.touched && t(meta.error ?? "")}
                  onBlur={field.onBlur}
                  onKeyDown={handlePreventDefault}
                  onPaste={handlePreventDefault}
                  autoComplete="off"
                  {...textFieldProps}
                />
              );
            }}
          />
        );
      }}
    </Field>
  );
};

export default DatePickerWrapper;
