import { SidebarItemsType } from "../../types/sidebar";

import { Users, User, Settings } from "react-feather";
import {
  Dashboard,
  Apartment,
  BrandingWatermark,
  Message,
} from "@mui/icons-material";

const onboardSection = [
  {
    href: "/onboard",
    icon: Dashboard,
    title: "Onboarding",
    permissionField: "OnboardSetting",
  },
] as SidebarItemsType[];

const popUpBannerSection = [
  {
    href: "/pop-up-banner",
    icon: Dashboard,
    title: "Homepage",
    permissionField: "PopUpBannerSetting",
  },
] as SidebarItemsType[];

const memberTierSection = [
  {
    href: "/member-tier",
    icon: Apartment,
    title: "Member Tiering",
    permissionField: "MemberTierSetting",
  },
] as SidebarItemsType[];

const newsSection = [
  {
    href: "/news",
    icon: Apartment,
    title: "News",
    permissionField: "NewsSetting",
  },
] as SidebarItemsType[];

const productSection = [
  {
    href: "/product",
    icon: Apartment,
    title: "Products",
    permissionField: "ProductSetting",
  },
] as SidebarItemsType[];

const branchSection = [
  {
    href: "/branch-management",
    icon: Apartment,
    title: "Store Info",
    permissionField: "BranchSetting",
  },
] as SidebarItemsType[];

const aboutCovaSection = [
  {
    href: "/about-cova",
    icon: Settings,
    title: "About Cova",
    permissionField: "AboutCovaSetting",
  },
] as SidebarItemsType[];

const faqSection = [
  {
    href: "/faq",
    icon: Settings,
    title: "FAQ",
    permissionField: "FAQSetting",
  },
] as SidebarItemsType[];

const generalSection = [
  {
    href: "/general-management",
    icon: Settings,
    title: "General Information",
    permissionField: "GeneralSetting",
  },
] as SidebarItemsType[];

const appVersionSection = [
  {
    href: "/app-management",
    icon: Settings,
    title: "App Maintenance & Versioning",
    permissionField: "AppVersionSetting",
  },
] as SidebarItemsType[];

const userSection = [
  {
    href: "/role-permission",
    icon: Users,
    title: "User Role",
    permissionField: "UserRoleSetting",
  },
  {
    href: "/accounts",
    icon: User,
    title: "User Account",
    permissionField: "UserSetting",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Popup Banner Management",
    pages: popUpBannerSection,
    permission: "CmsPopUpBannerManagement",
  },
  {
    title: "Onboard Management",
    pages: onboardSection,
    permission: "CmsOnboardManagement",
  },
  {
    title: "Member Tier Management",
    pages: memberTierSection,
    permission: "CmsMemberTierManagement",
  },
  {
    title: "News Management",
    pages: newsSection,
    permission: "CmsNewsManagement",
  },
  {
    title: "Product Management",
    pages: productSection,
    permission: "CmsProductManagement",
  },
  {
    title: "Store Management",
    pages: branchSection,
    permission: "CmsBranchManagement",
  },
  {
    title: "About Cova",
    pages: aboutCovaSection,
    permission: "CmsAboutCovaManagement",
  },
  {
    title: "FAQ Management",
    pages: faqSection,
    permission: "CmsFAQManagement",
  },
  {
    title: "General Management",
    pages: generalSection,
    permission: "CmsGeneralManagement",
  },
  {
    title: "App Maintenance & Versioning",
    pages: appVersionSection,
    permission: "CmsAppVersionManagement",
  },
  {
    title: "Users Control",
    pages: userSection,
    permission: "CmsUserManagement",
  },
];

export default navItems;
