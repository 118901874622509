import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { Avatar, Paper } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import SignInComponent from "../../components/auth/SignIn";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
function SignIn() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title={t("Login")} />
        <Avatar sx={{ m: "auto", bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
