import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
} from "@mui/material";

import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import { ImageField } from "../../components/form/ImageField";
import SwitchWrapper from "../../components/form/SwitchWrapper";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import moment from "moment";
import DateTimePickerWrapper from "../../components/form/DatePickerWrapper";
import { useMemo } from "react";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type OnboardValue = {
  id?: number;
  status: number;
  titleTc: string;
  titleEn: string;
  contentTc: string;
  contentEn: string;
  image: File | null;
  imageUrl?: string;
};

export const initialValues: OnboardValue = {
  titleTc: "",
  titleEn: "",
  contentEn: "",
  contentTc: "",
  status: 0,
  image: null,
};

type EditOnboardFormProps = {
  values?: OnboardValue;
  handleSubmit: (values: OnboardValue) => Promise<void>;
  submitError?: string;
};

export function OnboardForm({
  values,
  handleSubmit,
  submitError,
}: EditOnboardFormProps) {
  const { t } = useTranslation();
  const today = moment().startOf("day").toDate();
  const MAX_FILE_SIZE = 5;

  const isUpdate = useMemo(() => {
    return values?.id !== null && values?.id !== undefined;
  }, [values?.id]);

  const validationSchema = Yup.object().shape({
    titleTc: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    titleEn: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    contentTc: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    contentEn: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    image: Yup.mixed()
      .required("Required")
      .test("maxSize", t("form.error.invalidFileSize"), (value) =>
        typeof value === "string"
          ? true
          : value?.size < MAX_FILE_SIZE * 1000 * 1000
      ),
    status: Yup.number().integer(),
  });

  return (
    <Formik
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <SwitchWrapper name="status" label={t("Status")} />
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="titleTc"
                        label={t("page.onboard.form.nameTc")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="titleEn"
                        label={t("page.onboard.form.nameEn")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="contentTc"
                        label={t("page.onboard.form.detailTc")}
                        minRows={3}
                        multiline
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="contentEn"
                        label={t("page.onboard.form.detailEn")}
                        minRows={3}
                        multiline
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <ImageField
                      fieldName="image"
                      header={t("page.onboard.form.image")}
                      buttonText="UPLOAD ONBOARD"
                      remarks={[
                        "Size: width 1140 x height  933",
                        "Supported format: png, jpg",
                        `Max. file size: ${MAX_FILE_SIZE}MB`,
                      ]}
                      isUpdate={isUpdate}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {t("form.save")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/onboard" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default OnboardForm;
