import React from "react";
import { NavLink } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Can } from "@casl/react";
import { Ability } from "@casl/ability";
import useAuth from "../../../hooks/useAuth";

type BrandActionButtonProps = {
  row: any;
  fetchData: () => void;
};

function UserRoleActionButton({ row, fetchData }: BrandActionButtonProps) {
  const { t } = useTranslation();
  const { userAbility } = useAuth();

  return (
    <Grid>
      <Can
        do="updateAll"
        on="CmsUserManagement"
        field="UserRoleSetting"
        ability={(userAbility as any) ?? new Ability()}
      >
        <Button
          variant="contained"
          color="primary"
          type="submit"
          component={NavLink}
          to={"./edit/" + row.id}
        >
          {t("Edit")}
        </Button>
      </Can>
    </Grid>
  );
}
export default UserRoleActionButton;
