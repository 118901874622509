import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ButtonProps extends SpacingProps {
  component?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);

type CancelButtonWithDialogWithoutFormikProps = {
  isChanged: boolean;
  backUrl: string;
};

function CancelButtonWithDialogWithoutFormik({
  isChanged,
  backUrl,
}: CancelButtonWithDialogWithoutFormikProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={() => {
          if (isChanged) {
            setIsCancelDialogOpen(true);
          } else {
            navigate(backUrl);
          }
        }}
      >
        {t("Cancel")}
      </Button>

      <Dialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">
          {t("Confirm to Cancel ?")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            {t("Any unsaved data will be lost")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate(backUrl);
            }}
            color="error"
          >
            {t("form.confirm")}
          </Button>
          <Button
            onClick={() => setIsCancelDialogOpen(false)}
            color="primary"
            autoFocus
          >
            {t("form.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CancelButtonWithDialogWithoutFormik;
