// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import Page404 from "./pages/auth/Page404";

// Auth components
import SignIn from "./pages/auth/SignIn";

import CreateBranchPage from "./pages/BranchManagement/CreateBranch";
import EditBranchPage from "./pages/BranchManagement/EditBranch";
import EditUser from "./pages/UserManagement/EditUser";
import BranchListPage from "./pages/BranchManagement/BranchListing/BranchListPage";
import CreateUserPage from "./pages/UserManagement/CreateUser";
import SettingPage from "./pages/SettingsManagement/SettingPage";
import UserRoleListPage from "./pages/UserRoleManagement/UserRoleListing/UserRoleListPage";
import CreateUserRolePage from "./pages/UserRoleManagement/CreateUserRolePage";
import EditUserRolePage from "./pages/UserRoleManagement/EditUserRolePage";
import UserListPage from "./pages/UserManagement/UserListing/UserListPage";
import ResetPassword from "./pages/auth/ResetPassword";
import FAQPage from "./pages/FAQManagement";
import CreateFAQPage from "./pages/FAQManagement/CreateFAQ";
import EditFAQPage from "./pages/FAQManagement/EditFAQ";
import EditFAQSequencePage from "./pages/FAQManagement/EditFAQSequence";
import MemberTierPage from "./pages/MemberTierPage";
import EditMemberTierPage from "./pages/MemberTierPage/EditMemberTier";
import CreateMemberTierPage from "./pages/MemberTierPage/CreateMemberTier";
import ProductPage from "./pages/ProductManagement";
import EditProductPage from "./pages/ProductManagement/EditProduct";
import CreateProductPage from "./pages/ProductManagement/CreateProduct";
import EditProductSequencePage from "./pages/ProductManagement/EditProductSequence";
import NewsPage from "./pages/NewsManagement";
import CreateNewsPage from "./pages/NewsManagement/CreateNews";
import EditNewsPage from "./pages/NewsManagement/EditNews";
import EditNewsSequencePage from "./pages/NewsManagement/EditNewsSequence";
import EditBranchSequencePage from "./pages/BranchManagement/EditBranchSequence";
import GeneralPage from "./pages/GeneralManagement";
import OnboardPage from "./pages/OnboardManagement";
import CreateOnboardPage from "./pages/OnboardManagement/CreateOnboard";
import EditOnboardPage from "./pages/OnboardManagement/EditOnboard";
import EditOnboardSequencePage from "./pages/OnboardManagement/EditOnboardSequence";
import EditPrivacyPage from "./pages/GeneralManagement/PrivacyPolicy/EditPrivacyPolicyTnc";
import EditDeactivationTncPage from "./pages/GeneralManagement/DeactivationTnc/EditDeactivationTnc";
import EditRegistrationTncPage from "./pages/GeneralManagement/RegistrationTnc/EditRegistraTnc";
import EditGeneralTncPage from "./pages/GeneralManagement/GeneralTnc/EditGeneralTnc";
import EditLicensePermitPage from "./pages/GeneralManagement/LicensePermit/EditLicensePermit";
import AboutCovaPage from "./pages/AboutCovaManagement";
import CreateAboutCovaPage from "./pages/AboutCovaManagement/CreateAboutCova";
import EditAboutCovaPage from "./pages/AboutCovaManagement/EditAboutCova";
import EditAboutCovaSequencePage from "./pages/AboutCovaManagement/EditAboutCovaSequence";
import HomePage from "./pages/HomePage";
import PopUpBannerPage from "./pages/PopUpBanneManagement";
import CreatePopUpBannerPage from "./pages/PopUpBanneManagement/CreatePopUpBanner";
import EditPopUpBannerPage from "./pages/PopUpBanneManagement/EditPopUpBanner";
import EditPopUpBannerSequencePage from "./pages/PopUpBanneManagement/EditPopUpBannerSequence";

const routes = [
  {
    path: "login",
    element: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
  },
  {
    path: "reset-password",
    element: (
      <AuthLayout>
        <ResetPassword />
      </AuthLayout>
    ),
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/onboard",
        children: [
          {
            index: true,
            element: <OnboardPage />,
          },
          {
            path: "/onboard/create",
            element: <CreateOnboardPage />,
          },
          {
            path: "/onboard/edit/:id",
            element: <EditOnboardPage />,
          },
          {
            path: "/onboard/edit-sequence",
            element: <EditOnboardSequencePage />,
          },
        ],
      },
      {
        path: "/pop-up-banner",
        children: [
          {
            index: true,
            element: <PopUpBannerPage />,
          },
          {
            path: "/pop-up-banner/create",
            element: <CreatePopUpBannerPage />,
          },
          {
            path: "/pop-up-banner/edit/:id",
            element: <EditPopUpBannerPage />,
          },
          {
            path: "/pop-up-banner/edit-sequence",
            element: <EditPopUpBannerSequencePage />,
          },
        ],
      },
      {
        path: "/member-tier",
        children: [
          {
            index: true,
            element: <MemberTierPage />,
          },
          {
            path: "/member-tier/create",
            element: <CreateMemberTierPage />,
          },
          {
            path: "/member-tier/edit/:id",
            element: <EditMemberTierPage />,
          },
        ],
      },
      {
        path: "/news",
        children: [
          { index: true, element: <NewsPage /> },
          {
            path: "/news/create",
            element: <CreateNewsPage />,
          },
          {
            path: "/news/edit/:id",
            element: <EditNewsPage />,
          },
          {
            path: "/news/edit-sequence",
            element: <EditNewsSequencePage />,
          },
        ],
      },
      {
        path: "/product",
        children: [
          { index: true, element: <ProductPage /> },
          {
            path: "/product/create",
            element: <CreateProductPage />,
          },
          {
            path: "/product/edit/:id",
            element: <EditProductPage />,
          },
          {
            path: "/product/edit-sequence",
            element: <EditProductSequencePage />,
          },
        ],
      },
      {
        path: "/branch-management",
        children: [
          {
            index: true,
            element: <BranchListPage />,
          },
          {
            path: "/branch-management/create",
            element: <CreateBranchPage />,
          },
          {
            path: "/branch-management/edit/:branchId",
            element: <EditBranchPage />,
          },
          {
            path: "/branch-management/edit-sequence",
            element: <EditBranchSequencePage />,
          },
        ],
      },
      {
        path: "/about-cova",
        children: [
          { index: true, element: <AboutCovaPage /> },
          {
            path: "/about-cova/create",
            element: <CreateAboutCovaPage />,
          },
          {
            path: "/about-cova/edit/:id",
            element: <EditAboutCovaPage />,
          },
          {
            path: "/about-cova/edit-sequence",
            element: <EditAboutCovaSequencePage />,
          },
        ],
      },
      {
        path: "/faq",
        children: [
          {
            path: "/faq",
            element: <FAQPage />,
          },
          {
            path: "/faq/create",
            element: <CreateFAQPage />,
          },
          {
            path: "/faq/edit/:id",
            element: <EditFAQPage />,
          },
          {
            path: "/faq/edit-sequence",
            element: <EditFAQSequencePage />,
          },
        ],
      },

      // {
      //   path: "/faq-category",
      //   children: [
      //     {
      //       path: "/faq-category",
      //       element: <FAQCategoryPage />,
      //     },
      //     {
      //       path: "/faq-category/create",
      //       element: <CreateFAQCategoryPage />,
      //     },
      //     {
      //       path: "/faq-category/edit/:id",
      //       element: <EditFAQCategoryPage />,
      //     },
      //     {
      //       path: "/faq-category/edit-sequence",
      //       element: <EditFAQCategorySequencePage />,
      //     },
      //   ],
      // },
      {
        path: "/general-management",
        children: [
          {
            index: true,
            element: <GeneralPage />,
          },
          {
            path: "/general-management/tnc/edit",
            element: <EditGeneralTncPage />,
          },
          {
            path: "/general-management/registration-tnc/edit",
            element: <EditRegistrationTncPage />,
          },
          {
            path: "/general-management/deactivation-tnc/edit",
            element: <EditDeactivationTncPage />,
          },
          {
            path: "/general-management/privacy-policy/edit",
            element: <EditPrivacyPage />,
          },
          {
            path: "/general-management/license-policy/edit",
            element: <EditLicensePermitPage />,
          },
        ],
      },

      {
        path: "/app-management",
        element: <SettingPage />,
      },

      {
        path: "/accounts",
        children: [
          { index: true, element: <UserListPage /> },
          {
            path: "/accounts/create",
            element: <CreateUserPage />,
          },
          {
            path: "/accounts/edit/:userId",
            element: <EditUser />,
          },
        ],
      },

      {
        path: "/role-permission",
        children: [
          { index: true, element: <UserRoleListPage /> },
          {
            path: "/role-permission/create",
            element: <CreateUserRolePage />,
          },
          {
            path: "/role-permission/edit/:userRoleId",
            element: <EditUserRolePage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
