import React, { useCallback } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import generalStatus from "../../../config/status.json";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Grid,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BranchSearchBar from "./BranchSearchBar";
import BranchActionButton from "./BranchActionButton";
import { useDelayedAxios } from "../../../utils/useDelayedAxios";
import { useAxioses } from "../../../utils/useAxios";
import GridToolbar from "../../../components/datagrid/GridToolBar";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const mappedGeneralStatus = generalStatus.reduce((mapped, current) => {
  mapped[current.id] = { ...current };
  return mapped;
}, {});

interface columnDef extends GridColDef {
  searchable: boolean;
  readable?: boolean;
  validation?: object;
  editType?: string;
  searchType?: string;
  searchRef?: Record<number, string>;
}

function BranchListPage() {
  const { t } = useTranslation();
  const [where, setWhere] = useState<Record<string, any>>({});
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(25);
  const [sort, setSort] = useState<any[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]); // For Data Grid

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (JSON.stringify(newModel) !== JSON.stringify(sort)) {
      //Prevent infinite loop caused by mui dataGrid
      setSort(newModel);
    }
  };

  const { fetch, response, isLoading } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/branch/findByCriteria`
  );
  const rows = response?.[0] ?? [];
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setRowCount((prevRowCount) =>
      response?.[1] !== undefined ? response?.[1] : prevRowCount
    );
  }, [response]);

  const columns: GridColumns[] = [
    {
      field: "branchCode",
      headerName: t("page.branch.form.branchCode"),
      width: 180,
      editable: false,
      searchable: true,
    },
    {
      field: "titleEn",
      headerName: t("page.branch.form.name"),
      minWidth: 200,
      flex: 1,
      editable: false,
      searchable: false,
    },
    {
      field: "district",
      headerName: t("page.branch.form.district"),
      width: 180,
      editable: false,
      searchable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params: any) => (
        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {params.value?.nameEn}
        </p>
      ),
    },
    {
      field: "status",
      headerName: t("form.status"),
      width: 180,
      sortable: true,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <p>{params.value ? t("component.on") : t("component.off")}</p>
      ),
    },
    {
      field: "edit",
      headerName: t("form.edit"),
      width: 150,
      editable: false,
      renderCell: (row) => (
        <BranchActionButton row={row} fetchData={fetchData} />
      ),
      searchable: false,
      sortable: false,
    },
  ];

  const fetchData = useCallback(async () => {
    let whereCriteria = { ...where };

    let order = {};
    if (sort.length > 0) {
      order = {
        [sort[0].field]: sort[0].sort.toUpperCase(),
      };
    }

    const param = {
      where: whereCriteria,
      skip: skip,
      take: take,
      order,
    };

    fetch({ data: param });
  }, [where, skip, take, sort, fetch]);

  useEffect(() => {
    fetchData();
  }, [where, fetchData]);

  return (
    <React.Fragment>
      <Helmet title={t("page.branch.storeInfo")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("page.branch.storeInfo")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Typography>{t("page.branch.storeInfo")}</Typography>
      </Breadcrumbs>

      <Divider my={2} />

      <BranchSearchBar
        columns={columns}
        where={where}
        setWhere={setWhere}
        fetchData={fetchData}
      />

      <Grid my={5}>
        <DataGrid
          disableColumnMenu={true}
          components={{
            Toolbar: () =>
              GridToolbar({
                subject: "CmsBranchManagement",
                field: "BranchSetting",
                enableCreate: true,
                createLink: "/branch-management/create",
                createText: t("component.create"),
                enableDelete: true,
                enableEditSequence: true,
                editSequenceLink: "/branch-management/edit-sequence",
                editSequenceText: t("component.sorting"),
                deleteApi:
                  process.env.REACT_APP_CONTENT_SERVICE_URL +
                  "/cms/branch/deleteMultiple",
                deleteSuccessText: t("form.success.delete"),
                selectionModel: selectionModel,
                fetchData: fetchData,
              }),
          }}
          rowsPerPageOptions={[5, 10, 25]}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          pageSize={take}
          pagination
          paginationMode="server"
          onPageSizeChange={(newPageSize) => {
            setTake(newPageSize);
          }}
          onPageChange={(newPage) => {
            setSkip(newPage * take);
          }}
          sortingMode="server"
          sortModel={sort}
          onSortModelChange={handleSortModelChange}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          loading={isLoading}
          autoHeight
        />
      </Grid>
    </React.Fragment>
  );
}

export default BranchListPage;
