import { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@mui/material";

import { useDelayedAxios } from "../../utils/useDelayedAxios";
import NewsForm, { NewsValues } from "./NewsForm";
import { LinkType } from "./news.enum";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);

// Form Component
function CreateNewsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/news`
  );

  const handleSubmit = async (data: NewsValues) => {
    switch (data.linkType) {
      case LinkType.NONE: {
        delete data.link;
        delete data.deepLink;
        delete data.deepLinkId;
        break;
      }
      case LinkType.URI: {
        delete data.deepLink;
        delete data.deepLinkId;
        break;
      }
      case LinkType.DEEP_LINK: {
        delete data.link;
        break;
      }
    }
    const { imageUrl, ...body } = data;

    const formData = Object.keys(body).reduce((formData, key) => {
      // special handling for nullable field
      if (body[key] || typeof body[key] === "number" || key === "titleTc")
        formData.append(key, body[key]);
      return formData;
    }, new FormData());

    await submit({ data: formData });
  };

  // Redirect when create success
  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/news"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("component.create")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("component.create")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/news">
          {t("page.news.title")}
        </Link>
        <Typography>{t("component.create")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {isSubmitSuccess ? (
        <Card mb={6}>
          <CardContent>
            <Alert severity="success" my={3}>
              {t("form.success.create")}
            </Alert>
          </CardContent>
        </Card>
      ) : (
        <NewsForm handleSubmit={handleSubmit} submitError={submitError} />
      )}
    </>
  );
}

export default CreateNewsPage;
