import axios from "axios";

const axiosInstance = axios.create({
  headers: {
    post: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "X-Requested-With",
    },
  },
  timeout: parseInt(process.env.REACT_APP_AXIOS_TIMEOUT || ""),
});

axiosInstance.interceptors.request.use(function (request: any) {
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const accessToken = window.localStorage.getItem("accessToken");
      // ! Only when the user already login
      if (accessToken) {
        alert("Time out session, please login again.");
        window.localStorage.removeItem("accessToken");
        return (window.location.href = "/admin/login");
      }
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

const accessToken = window.localStorage.getItem("accessToken");
axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

export default axiosInstance;
