import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  CardContent,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import { useTranslation } from "react-i18next";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import { Card as MuiCard } from "@mui/material";
import { formSubmitNavigationDelayTime } from "../../config/const";
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const Card = styled(MuiCard)(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_USER_SERVICE_URL}/password/reset`
  );

  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/login"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return isSubmitSuccess ? (
    <Card mb={6}>
      <CardContent>
        <Alert severity="success" my={3}>
          {t("Reset password successfully! New password was send by email")}
        </Alert>
      </CardContent>
    </Card>
  ) : (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Must be a valid email"))
          .max(255)
          .required(t("Email is required")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        await submit({ data: { email: values.email } });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={t("Email Address")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          {submitError && (
            <Alert mt={2} mb={3} severity="warning">
              {submitError}
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t("Reset Password")}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
