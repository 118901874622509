import { createContext, useContext } from "react";

type SidebarContextType = {
  onClose: () => void;
};

export const SidebarContext = createContext<SidebarContextType>({
  onClose: () => undefined,
});
export const useSidebar = () => useContext(SidebarContext);
