import { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";

import EditGeneralTncForm, { EditGeneralTncValues } from "./EditGeneralTncForm";
import { GeneralSection } from "../../../types/general";
import { useAxioses } from "../../../utils/useAxios";
import { useDelayedAxios } from "../../../utils/useDelayedAxios";
import { formSubmitNavigationDelayTime } from "../../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);

const initialValues: EditGeneralTncValues = {
  tncContentTc: "",
  tncContentEn: "",
  section: GeneralSection.TNC,
};

// Form Component
function EditGeneralTncPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    response: initResponse,
    isError: isInitError,
    error: initError,
    isLoading: isInitLoading,
  } = useAxioses([
    {
      method: "POST",
      url: `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/general/tnc/findBySection`,
      data: {
        section: GeneralSection.TNC,
      },
    },
  ]);

  const generalTnc = initResponse?.[0].data;

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/general/tnc/upsert`
  );

  const handleSubmit = async (data: EditGeneralTncValues) => {
    await submit({
      data: { ...data, section: GeneralSection.TNC },
    });
  };

  // Redirect when create success
  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/general-management"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("page.general.edit.tnc.general")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("page.general.edit.tnc.general")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/general-management">
          {t("page.general.title")}
        </Link>
        <Typography>{t("page.general.edit.tnc.general")}</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      {isSubmitSuccess ? (
        <Card mb={6}>
          <CardContent>
            <Alert severity="success" my={3}>
              {t("form.success.update")}
            </Alert>
          </CardContent>
        </Card>
      ) : isInitLoading ? (
        <LinearProgress />
      ) : isInitError ? (
        <Alert severity="warning" my={3}>
          {initError}
        </Alert>
      ) : (
        <EditGeneralTncForm
          initialValues={generalTnc ?? initialValues}
          handleSubmit={handleSubmit}
          submitError={submitError}
        />
      )}
    </>
  );
}

export default EditGeneralTncPage;
