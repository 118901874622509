import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

type ConfirmDialogProps = {
  handleClose: () => void;
  handleConfirm: () => void;
  open: boolean;
  content?: string;
};

function ConfirmDialog({
  open,
  handleClose,
  handleConfirm,
  content,
}: ConfirmDialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("Are you sure?")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content || t("Are you sure to make this changes?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("No")}
        </Button>
        <Button onClick={handleConfirm} color="error" autoFocus>
          {t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
