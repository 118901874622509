import React, { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { ErrorMessage } from "formik";
import { Box, Paper, styled } from "@mui/material";

export const errorStyle = {
  color: "#d32f2f",
  fontFamily: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  fontWeight: 400,
  fontSize: "0.6964285714285714rem",
  lineHeight: 1.66,
  marginTop: "4px",
  marginRight: "14px",
  marginBottom: 0,
  marginLeft: "14px",
};

const editorConfiguration = {
  toolbar: [
    "heading",
    "fontFamily",
    "fontSize",
    "fontColor",
    "fontBackgroundColor",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "alignment",
    "numberedList",
    "bulletedList",
    "link",
    "indent",
    "outdent",
    "insertImage",
    "insertImageFromUnsplash",
    "code",
    "codeBlock",
    "blockQuote",
    "insertTable",
    "mediaEmbed",
    "undo",
    "redo",
  ],
};

type CKEditorProps = {
  name: string;
  label?: string;
  data: string;
  setFieldValue: (field: string, value: any) => void;
  config?: { toolbar: string[] };
};

export const CKEditorWrapper = ({
  name,
  label,
  data,
  setFieldValue,
  config,
}: CKEditorProps) => {
  const editorRef = useRef<any>(null);

  return (
    <>
      <Box>{label}</Box>
      <Container>
        <CKEditor
          name={name}
          editor={DecoupledEditor}
          data={data}
          onChange={(event, editor) => {
            setFieldValue(name, editor.getData());
          }}
          onReady={(editor) => {
            editor &&
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );
            editorRef.current = editor;
          }}
          onError={(error, { willEditorRestart }) => {
            if (willEditorRestart) {
              editorRef.current &&
                editorRef.current.ui.view.toolbar.element.remove();
            }
          }}
          config={config || editorConfiguration}
        />
      </Container>
      <ErrorMessage
        name={name}
        render={(msg) => <p style={errorStyle}>{msg}</p>}
      />
    </>
  );
};

const Container = styled(Paper)`
  border-radius: 4px;
  border: solid 1px #e0e0e0;

  .ck-editor__editable_inline {
    min-height: 75px;
  }
`;
