import React, { useEffect, useState } from "react";
import { Action } from "cova-db-schema";
import { useTranslation } from "react-i18next";

import RemoveIcon from "@mui/icons-material/Remove";

import {
  Box,
  Button,
  Checkbox,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { useFormikContext } from "formik";
import { EditUserRoleFormValues } from "./EditUserRoleForm";
import _ from "lodash";

export type PermissionType = {
  action: Action;
  subject: [string?];
};

export type MatrixSection = {
  name: string;
  subject: string;
  functions: MatrixFunction[];
};

export type MatrixFunction = {
  name: string;
  field: string;
  enabled: {
    readAll?: boolean;
    create?: boolean;
    updateAll?: boolean;
    deleteAll?: boolean;
    exportAll?: boolean;
  };
};

export type CmsSection =
  | "CmsDashboard"
  | "CmsOnboardManagement"
  | "CmsMemberTierManagement"
  | "CmsNewsManagement"
  | "CmsProductManagement"
  | "CmsPopUpBannerManagement"
  | "CmsBranchManagement"
  | "CmsAboutCovaManagement"
  | "CmsFAQManagement"
  | "CmsGeneralManagement"
  | "CmsAppVersionManagement"
  | "CmsUserManagement";

export type CmsFunction =
  | "Dashboard"
  | "OnboardSetting"
  | "MemberTierSetting"
  | "NewsSetting"
  | "ProductSetting"
  | "BranchSetting"
  | "AboutCovaSetting"
  | "FAQSetting"
  | "GeneralSetting"
  | "AppSetting"
  | "UserRoleSetting"
  | "AppVersionSetting"
  | "UserSetting";

export const cmsFunctionList: MatrixSection[] = [
  {
    name: "Onboard Management",
    subject: "CmsOnboardManagement",
    functions: [
      {
        name: "Onboarding Setting",
        field: "OnboardSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },
  {
    name: "Member Tier Management",
    subject: "CmsMemberTierManagement",
    functions: [
      {
        name: "Member Tiering Setting",
        field: "MemberTierSetting",
        enabled: {
          readAll: true,
          updateAll: true,
        },
      },
    ],
  },
  {
    name: "News Management",
    subject: "CmsNewsManagement",
    functions: [
      {
        name: "News Setting",
        field: "NewsSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },
  {
    name: "Product Management",
    subject: "CmsProductManagement",
    functions: [
      {
        name: "Products Setting",
        field: "ProductSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },
  {
    name: "Pop Up Banner Management",
    subject: "CmsPopUpBannerManagement",
    functions: [
      {
        name: "Pop Up Banner Setting",
        field: "PopUpBannerSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },
  {
    name: "Branch Management",
    subject: "CmsBranchManagement",
    functions: [
      {
        name: "Store Info Setting",
        field: "BranchSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },

  {
    name: "About Cova",
    subject: "CmsAboutCovaManagement",
    functions: [
      {
        name: "About Cova Setting",
        field: "AboutCovaSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },

  {
    name: "FAQ Management",
    subject: "CmsFAQManagement",
    functions: [
      {
        name: "FAQ Setting",
        field: "FAQSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },

  {
    name: "General Management",
    subject: "CmsGeneralManagement",
    functions: [
      {
        name: "General Information Setting",
        field: "GeneralSetting",
        enabled: {
          readAll: true,
          updateAll: true,
        },
      },
    ],
  },

  {
    name: "App Maintenance & Versioning",
    subject: "CmsAppVersionManagement",
    functions: [
      {
        name: "App Maintenance & Versioning Setting",
        field: "AppVersionSetting",
        enabled: {
          readAll: true,
          updateAll: true,
        },
      },
    ],
  },
  {
    name: "Users",
    subject: "CmsUserManagement",
    functions: [
      {
        name: "User Role Setting",
        field: "UserRoleSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
      {
        name: "Accounts Setting",
        field: "UserSetting",
        enabled: {
          readAll: true,
          create: true,
          updateAll: true,
          deleteAll: true,
        },
      },
    ],
  },
];

const permissionColumns = [
  { key: Action.ReadAll, action: "readAll", name: "Read" },
  { key: Action.Create, action: "create", name: "Create" },
  { key: Action.UpdateAll, action: "updateAll", name: "Edit" },
  { key: Action.DeleteAll, action: "deleteAll", name: "Delete" },
  // { key: Action.ExportAll, action: "exportAll", name: "Export" },
];

let allTruePermission = {};
cmsFunctionList.forEach((section) => {
  allTruePermission[section.subject] = {};
  section.functions.forEach((cmsFunction) => {
    allTruePermission[section.subject][cmsFunction.field] = {};
    permissionColumns.forEach(
      (permission) =>
        (allTruePermission[section.subject][cmsFunction.field][
          permission.action
        ] = true)
    );
  });
});

function checkWholeColumnIsChecked(action: string, permission) {
  console.log({ permission });
  if (!permission) return false;
  if (_.isEmpty(permission)) return false;
  for (let { subject, functions } of cmsFunctionList) {
    for (let { field, enabled } of functions) {
      if (!enabled[action]) continue; // check the action is enabled in permission section function list
      if (!permission[subject]) return false; // in case just unselect All and become empty object
      if (!permission[subject][field]) return false; // in case just unselect All and become empty object
      if (!permission[subject][field][action]) return false; // check the action permission of corresponding subject field is true or false
    }
  }
  return true;
}

export default function PermissionMatrix({
  isSuperUser,
}: {
  isSuperUser: boolean;
}) {
  const { t } = useTranslation();
  const { values, handleChange, setFieldValue } =
    useFormikContext<EditUserRoleFormValues>();

  const [permissionCurrentColumnStatus, setPermissionCurrentColumnStatus] =
    useState({});

  // Check if the whole column are checked
  useEffect(() => {
    const data = {};
    permissionColumns.forEach((permissionColumn) => {
      data[permissionColumn.action] = checkWholeColumnIsChecked(
        permissionColumn.action,
        values?.permission
      );
    });
    setPermissionCurrentColumnStatus(data);
  }, [values?.permission]);

  const toggleAllColumn = async (column: "all" | "none" | Action) => {
    switch (column) {
      case "all":
        setFieldValue("permission", allTruePermission);
        break;

      case "none":
      default:
        setFieldValue("permission", {});
        break;
    }
  };

  const selectColumnAction = (action: string, bool: boolean) => {
    const updatedPermission = _.cloneDeep(values.permission);

    cmsFunctionList.forEach((section) => {
      if (_.isEmpty(updatedPermission[section.subject])) {
        updatedPermission[section.subject] = {};
      }
      section.functions.forEach((cmsFunction) => {
        if (_.isEmpty(updatedPermission[section.subject][cmsFunction.field])) {
          updatedPermission[section.subject][cmsFunction.field] = {};
        }
        permissionColumns.forEach((permission) => {
          if (permission.action === action) {
            updatedPermission[section.subject][cmsFunction.field][
              permission.action
            ] = bool;
          }
        });
      });
    });

    setFieldValue("permission", updatedPermission);
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h4" gutterBottom>
              {t("Manage Permission")}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography variant="h6" gutterBottom>
              <Button
                onClick={() => {
                  toggleAllColumn("all");
                }}
                disabled={isSuperUser}
              >
                {t("Select All")}
              </Button>
              <Button
                onClick={() => {
                  toggleAllColumn("none");
                }}
                disabled={isSuperUser}
              >
                {t("Unselect All")}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table sx={{ mb: 3 }} stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("Function")}</StyledTableCell>
              {permissionColumns.map((column) => (
                <StyledTableCell key={column.key} align="center">
                  <Button>{t(column.name)}</Button>
                  <Checkbox
                    checked={
                      permissionCurrentColumnStatus[column.action] ?? false
                    }
                    onChange={(e) => {
                      selectColumnAction(column.action, e.target.checked);
                    }}
                  />
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {cmsFunctionList.map((section) => (
              <React.Fragment key={section.subject}>
                {section.functions.map((cmsFunction) => (
                  <React.Fragment key={cmsFunction.name}>
                    <TableRow>
                      <StyledTableCell>
                        <Typography pl={3}>{cmsFunction.name}</Typography>
                      </StyledTableCell>
                      {permissionColumns.map((column, permissionIndex) => {
                        return (
                          <StyledTableCell
                            key={`${cmsFunction}-${permissionIndex}`}
                            align="center"
                          >
                            {cmsFunction.enabled[column.action] ? (
                              <>
                                <Checkbox
                                  name={`permission.${section.subject}.${cmsFunction.field}.${column.action}`}
                                  checked={
                                    values?.permission?.[section.subject]?.[
                                      cmsFunction.field
                                    ]?.[column.action] ?? false
                                  }
                                  onChange={handleChange}
                                  disabled={isSuperUser}
                                />
                              </>
                            ) : (
                              <RemoveIcon color="disabled" />
                            )}
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </React.Fragment>
  );
}

const StyledTableCell = styled(TableCell)({
  padding: 0,
});
