import styled from "styled-components/macro";
import {
  Typography,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridColumns,
  GridSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import useAuth from "../../hooks/useAuth";
import { Can } from "@casl/react";
import { Ability } from "@casl/ability";
import { useCallback, useEffect, useState } from "react";
import GridToolbar from "../../components/datagrid/GridToolBar";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import moment from "moment";
import { awsConfig } from "../../config";
import ProductSearchBar from "./ProductSearchBar";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);

type whereType = { [key: string]: string | number | undefined };

const ProductPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [where, setWhere] = useState<whereType>({});
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(25);
  const [sort, setSort] = useState<any[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]); // For Data Grid

  const { fetch, response, isLoading } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/product/findByCriteria`
  );
  const rows = response?.[0] ?? [];
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setRowCount((prevRowCount) =>
      response?.[1] !== undefined ? response?.[1] : prevRowCount
    );
  }, [response]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (JSON.stringify(newModel) !== JSON.stringify(sort)) {
      //Prevent infinite loop caused by mui dataGrid
      setSort(newModel);
    }
  };

  const columns: GridColumns = [
    {
      field: "nameEn",
      headerName: t("page.product.form.nameEn"),
      minWidth: 180,
      flex: 1,
      sortable: true,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "nameTc",
      headerName: t("page.product.form.nameTc"),
      minWidth: 180,
      flex: 1,
      sortable: true,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: t("form.status"),
      width: 180,
      sortable: true,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <p>{params.value ? t("component.on") : t("component.off")}</p>
      ),
    },
    {
      field: "edit",
      headerName: t("component.edit"),
      width: 160,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { userAbility } = useAuth();
        return (
          <Can
            do="updateAll"
            on="CmsProductManagement"
            field="ProductSetting"
            ability={(userAbility as any) ?? new Ability()}
          >
            <Button
              variant="contained"
              color="primary"
              mr={1}
              onClick={() => navigate(`/product/edit/${params.id}`)}
            >
              {t("component.edit")}
            </Button>
          </Can>
        );
      },
    },
  ];

  const fetchData = useCallback(() => {
    let whereCriteria = { ...where };

    let order = {};
    if (sort.length > 0) {
      order = {
        [sort[0].field]: sort[0].sort.toUpperCase(),
      };
    }

    const param = {
      where: whereCriteria,
      skip: skip,
      take: take,
      order,
    };

    fetch({ data: param });
  }, [skip, take, where, sort, fetch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Helmet title={t("page.product.title")} />
      <Grid item md={2}>
        <Typography
          variant="h3"
          gutterBottom
          display="inline"
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("page.product.title")}
        </Typography>
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Typography>{t("page.product.title")}</Typography>
      </Breadcrumbs>
      <Divider my={2} />

      <ProductSearchBar
        columns={columns}
        where={where}
        setWhere={setWhere}
        fetchData={fetchData}
      />

      <Grid my={5}>
        <DataGrid
          disableColumnMenu={true}
          components={{
            Toolbar: () =>
              GridToolbar({
                subject: "CmsProductManagement",
                field: "ProductSetting",
                enableCreate: true,
                createLink: "/product/create",
                createText: t("component.create"),
                enableDelete: true,
                enableEditSequence: true,
                editSequenceLink: "/product/edit-sequence",
                editSequenceText: t("component.sorting"),
                deleteApi: `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/product/deleteMultiple`,
                deleteSuccessText: t("form.success.delete"),
                selectionModel: selectionModel,
                fetchData: fetchData,
              }),
          }}
          rowsPerPageOptions={[5, 10, 25]}
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          pageSize={take}
          pagination
          paginationMode="server"
          onPageSizeChange={(newPageSize) => {
            setTake(newPageSize);
          }}
          onPageChange={(newPage) => {
            setSkip(newPage * take);
          }}
          sortingMode="server"
          sortModel={sort}
          onSortModelChange={handleSortModelChange}
          checkboxSelection
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          loading={isLoading}
          autoHeight
        />
      </Grid>
    </>
  );
};

export default ProductPage;
