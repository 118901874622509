import { spacing, SpacingProps } from "@mui/system";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import * as Yup from "yup";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
} from "@mui/material";

import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import moment from "moment";
import { useMemo } from "react";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import { ImageField } from "../../components/form/ImageField";
import SwitchWrapper from "../../components/form/SwitchWrapper";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import { DeepLinkIdList, DeepLinkType, LinkType } from "./pop-up-banners.enum";
import { urlRegExp } from "../../regex";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type PopUpBannerValue = {
  id?: number;
  status: number;
  nameTc: string;
  nameEn: string;
  image: File | null;
  imageUrl?: string;
  linkType?: LinkType;
  link?: string;
  deepLink?: DeepLinkType;
  deepLinkId?: string;
};

export const initialValues: PopUpBannerValue = {
  nameTc: "",
  nameEn: "",
  status: 0,
  image: null,
  linkType: LinkType.NONE,
  link: "",
  deepLink: DeepLinkType.BRANCH,
  deepLinkId: "",
};

type EditPopUpBannerFormProps = {
  values?: PopUpBannerValue;
  handleSubmit: (values: PopUpBannerValue) => Promise<void>;
  submitError?: string;
};

export function PopUpBannerForm({
  values,
  handleSubmit,
  submitError,
}: EditPopUpBannerFormProps) {
  const { t } = useTranslation();
  const today = moment().startOf("day").toDate();
  const MAX_FILE_SIZE = 5;

  const isUpdate = useMemo(() => {
    return values?.id !== null && values?.id !== undefined;
  }, [values?.id]);

  const validationSchema = Yup.object().shape({
    nameTc: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    nameEn: Yup.string()
      .required("Required")
      .max(255, "Maximum 255 characters"),
    linkType: Yup.string().nullable(),
    link: Yup.string().when("linkType", {
      is: (linkType) => linkType === LinkType.URI,
      then: Yup.string()
        .required("Required")
        .nullable()
        .url(t("form.error.invalidUrl"))
        .matches(urlRegExp, t("form.error.invalidUrl")),
      otherwise: Yup.string().nullable(),
    }),
    deepLink: Yup.string().when(["linkType"], {
      is: (linkType) => linkType === LinkType.DEEP_LINK,
      then: Yup.string().required("Required"),
      otherwise: Yup.string().nullable(),
    }),
    deepLinkId: Yup.string().when(["deepLink", "linkType"], {
      is: (deepLink, linkType) =>
        linkType === LinkType.DEEP_LINK && DeepLinkIdList.includes(deepLink),
      then: Yup.string().required("Required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    image: Yup.mixed()
      .required("Required")
      .test("maxSize", t("form.error.invalidFileSize"), (value) =>
        typeof value === "string"
          ? true
          : value?.size < MAX_FILE_SIZE * 1000 * 1000
      ),
    status: Yup.number().integer(),
  });

  const linkTypeList = [
    {
      label: t("page.pop-up-banner.form.none"),
      value: LinkType.NONE,
    },

    {
      label: t("page.pop-up-banner.form.externalWebLink"),
      value: LinkType.URI,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.title"),
      value: LinkType.DEEP_LINK,
    },
  ];

  const deepLinkTypeList = [
    {
      label: t("page.pop-up-banner.form.deepLink.profile"),
      value: DeepLinkType.PROFILE,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.qrCode"),
      value: DeepLinkType.QR_CODE,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.memberTier"),
      value: DeepLinkType.MEMBER_TIER,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.news"),
      value: DeepLinkType.NEWS_ID,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.branch"),
      value: DeepLinkType.BRANCH,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.reward"),
      value: DeepLinkType.REWARD,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.faq"),
      value: DeepLinkType.FAQ,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.inbox_personal"),
      value: DeepLinkType.INBOX_PERSONAL,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.inbox_promotional"),
      value: DeepLinkType.INBOX_PROMOTIONAL,
    },
    {
      label: t("page.pop-up-banner.form.deepLink.ordering_site"),
      value: DeepLinkType.ORDERING_SITE,
    },
  ];

  return (
    <Formik
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <SwitchWrapper name="status" label={t("Status")} />
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="nameTc"
                        label={t("page.pop-up-banner.form.nameTc")}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextFieldWrapper
                        name="nameEn"
                        label={t("page.pop-up-banner.form.nameEn")}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={2}>
                      <TextFieldWrapper
                        name="linkType"
                        label={t("page.pop-up-banner.form.link")}
                        value={values.linkType}
                        select
                      >
                        {linkTypeList.map((type, index) => (
                          <MenuItem key={index} value={type.value}>
                            {type.label}
                          </MenuItem>
                        ))}
                      </TextFieldWrapper>
                    </Grid>

                    {values.linkType === LinkType.URI && (
                      <Grid item xs={12} md={8} lg={10}>
                        <TextFieldWrapper
                          name="link"
                          label={t("page.pop-up-banner.form.externalLink")}
                          onChange={(e) => {
                            setFieldValue(
                              "link",
                              e.target.value.replaceAll(" ", "")
                            );
                          }}
                        />
                      </Grid>
                    )}

                    {values.linkType === LinkType.DEEP_LINK && (
                      <Grid item xs={12} md={4} lg={2}>
                        <TextFieldWrapper
                          name="deepLink"
                          label={t("page.pop-up-banner.form.deepLink.title")}
                          value={values.deepLink}
                          select
                        >
                          {deepLinkTypeList.map((type, index) => (
                            <MenuItem key={index} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </TextFieldWrapper>
                      </Grid>
                    )}

                    {values.linkType === LinkType.DEEP_LINK &&
                      values.deepLink &&
                      DeepLinkIdList.includes(values.deepLink) && (
                        <Grid item xs={12} md={8} lg={6}>
                          <TextFieldWrapper
                            name="deepLinkId"
                            label={t("page.pop-up-banner.form.deepLinkId")}
                            onChange={(e) => {
                              setFieldValue(
                                "deepLinkId",
                                e.target.value.replaceAll(" ", "")
                              );
                            }}
                          />
                        </Grid>
                      )}
                  </Grid>

                  <Grid item xs={12}>
                    <ImageField
                      fieldName="image"
                      header={t("page.pop-up-banner.form.image")}
                      buttonText="UPLOAD HOMEPAGE"
                      remarks={[
                        "Size: width 1140 x height  2025",
                        "Supported format: png, jpg",
                        `Max. file size: ${MAX_FILE_SIZE}MB`,
                      ]}
                      isUpdate={isUpdate}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {t("form.save")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/pop-up-banner" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default PopUpBannerForm;
