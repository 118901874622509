import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import PermissionMatrix, { CmsFunction, CmsSection } from "./PermissionMatrix";
import { Action } from "cova-db-schema";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import SwitchWrapper from "../../components/form/SwitchWrapper";
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type EditUserRoleFormValues = {
  id?: number;
  name: string;
  status: number;
  permission: Record<
    CmsSection,
    undefined | Record<CmsFunction, Record<Action, boolean>>
  >;
};

type EditUserRoleFormProps = {
  initialValues: EditUserRoleFormValues;
  handleSubmit: (values: EditUserRoleFormValues) => Promise<void>;
  submitError?: string;
  isSuperUser: boolean;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  status: Yup.number().required(),
  sessions: Yup.array().min(1, "Required").of(Yup.number()),
});

export function EditUserRoleForm({
  initialValues,
  handleSubmit,
  submitError,
  isSuperUser,
}: EditUserRoleFormProps) {
  const { t, i18n } = useTranslation();
  const isUpdate =
    typeof initialValues?.id !== "undefined" && initialValues?.id !== null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        isSubmitting,
        touched,
        values,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  {t("page.userRole.title")}
                </Typography>

                <Grid item xs={12} sm={2}>
                  <SwitchWrapper name="status" label={t("form.status")} />
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextFieldWrapper
                      name="name"
                      label={t("page.userRole.form.roleName")}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card mb={6}>
              <CardContent>
                <PermissionMatrix isSuperUser={isSuperUser} />
              </CardContent>
            </Card>

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {isUpdate ? t("Save") : t("Create")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/role-permission" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default EditUserRoleForm;
