import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  styled,
} from "@mui/material";
import { Field } from "formik";
import React from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

type RadioItem<T> = {
  value: T;
  icon: React.ReactNode;
};

type RadioButtonWrapperProps<T> = {
  name: string;
  label: string;
  itemList: RadioItem<T>[];
};

function RadioButtonWrapper<T>({
  name,
  label,
  itemList,
  ...props
}: RadioButtonWrapperProps<T> & RadioGroupProps) {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
              name={name}
              onChange={(e) => {
                const value = e.target.value;
                form.setFieldValue(name, value);
              }}
              {...props}
            >
              <Stack direction="row" flexWrap="wrap" spacing={6}>
                {itemList.map(({ value, icon }, index) => {
                  return (
                    <Box key={index}>
                      <Radio
                        value={value}
                        checkedIcon={<CheckCircleRoundedIcon />}
                      />
                      {icon}
                    </Box>
                  );
                })}
              </Stack>
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
}

export default RadioButtonWrapper;
