import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../utils/useAxios";

import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";

import { useDelayedAxios } from "../../utils/useDelayedAxios";
import ProductForm, { ProductValues } from "./ProductForm";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);

// Form Component
function EditProductPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    response: initResponse,
    isError: isInitError,
    error: initError,
    isLoading: isInitLoading,
  } = useAxios(
    "GET",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/product/${id}`
  );

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "PATCH",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/product/${id}`
  );

  const handleSubmit = async (data: ProductValues) => {
    const { imageUrl, ...body } = data;

    const formData = Object.keys(body).reduce((prev, key) => {
      // special handling for nullable field
      if (
        body[key] ||
        typeof body[key] === "number" ||
        key === "nameTc" ||
        key === "link"
      )
        prev.append(key, body[key]);
      return prev;
    }, new FormData());

    await submit({ data: formData });
  };

  // Redirect when create success
  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/product"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("component.edit")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("component.edit")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/product">
          {t("page.product.title")}
        </Link>
        <Typography>{t("component.edit")}</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      {isSubmitSuccess ? (
        <Card mb={6}>
          <CardContent>
            <Alert severity="success" my={3}>
              {t("form.success.update")}
            </Alert>
          </CardContent>
        </Card>
      ) : isInitLoading ? (
        <LinearProgress />
      ) : isInitError ? (
        <Alert severity="warning" my={3}>
          {initError}
        </Alert>
      ) : (
        <ProductForm
          values={initResponse}
          handleSubmit={handleSubmit}
          submitError={submitError}
        />
      )}
    </>
  );
}

export default EditProductPage;
