import React, { useCallback } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import generalStatus from "../../../config/status.json";

import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSelectionModel,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserSearchBar from "./UserSearchBar";
import UserActionButton from "./UserActionButton";
import { useDelayedAxios } from "../../../utils/useDelayedAxios";
import GridToolbar from "../../../components/datagrid/GridToolBar";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const mappedGeneralStatus = generalStatus.reduce((mapped, current) => {
  mapped[current.id] = { ...current };
  return mapped;
}, {});

interface columnDef extends GridColDef {
  searchable?: boolean;
  readable?: boolean;
  validation?: object;
  editType?: string;
  searchType?: string;
  searchRef?: Record<number, string>;
}
type whereType = { [key: string]: string | number };

function UserListPage() {
  const { t } = useTranslation();
  const [where, setWhere] = useState<whereType>({});
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(25);
  const [sort, setSort] = useState<any[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]); // For Data Grid

  const handleSortModelChange = (newModel: GridSortModel) => {
    if (JSON.stringify(newModel) !== JSON.stringify(sort)) {
      //Prevent infinite loop caused by mui dataGrid
      setSelectionModel([]);
      setSort(newModel);
    }
  };

  const { fetch, response, isLoading } = useDelayedAxios(
    "POST",
    process.env.REACT_APP_USER_SERVICE_URL + "/users/findByCriteria"
  );
  const rows = response?.[0] ?? [];
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setRowCount((prevRowCount) =>
      response?.[1] !== undefined ? response?.[1] : prevRowCount
    );
  }, [response]);

  const columns: columnDef[] = [
    {
      field: "email",
      headerName: t("page.user.form.email"),
      width: 200,
      editable: false,
      searchable: true,
    },
    // {
    //   field: "userRole.name",
    //   valueGetter: (params) => params.row?.userRole?.name || "",
    //   headerName: t("page.user.form.userRole"),
    //   width: 200,
    //   editable: false,
    //   searchable: true,
    // },
    {
      field: "status",
      headerName: t("form.status"),
      width: 180,
      sortable: true,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <p>{params.value ? t("component.on") : t("component.off")}</p>
      ),
    },
    {
      field: "action",
      headerName: t("component.edit"),
      width: 150,
      readable: true,
      editable: false,
      sortable: false,
      renderCell: (row) => {
        const isSuperUser = row?.id === 0;
        if (isSuperUser) return <></>;
        return <UserActionButton row={row} fetchData={fetchData} />;
      },
    },
  ];

  const fetchData = useCallback(() => {
    let whereCriteria = { ...where };
    if (whereCriteria.userRoleId === -1) {
      delete whereCriteria.userRoleId;
    }
    if (whereCriteria.status === -1) {
      delete whereCriteria.status;
    }

    let order = {};
    if (sort.length > 0) {
      order = {
        [sort[0].field]: sort[0].sort.toUpperCase(),
      };
    }

    const param = {
      where: whereCriteria,
      skip: skip,
      take: take,
      order,
    };

    fetch({ data: param });
  }, [where, skip, take, sort, fetch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <React.Fragment>
      <Helmet title={t("User Account")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("User Account")}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Typography>{t("User Account")}</Typography>
      </Breadcrumbs>

      <Divider my={2} />

      <UserSearchBar
        columns={columns}
        setWhere={setWhere}
        fetchData={fetchData}
      />
      <Card mb={6}>
        <Paper>
          <DataGrid
            disableColumnMenu={true}
            components={{
              Toolbar: () =>
                GridToolbar({
                  subject: "CmsUserManagement",
                  field: "UserSetting",
                  enableCreate: true,
                  createLink: "/accounts/create",
                  createText: t("component.create"),
                  enableDelete: true,
                  deleteApi:
                    process.env.REACT_APP_USER_SERVICE_URL +
                    "/users/delete-users",
                  deleteSuccessText: t("Deleted User Successfully"),
                  selectionModel: selectionModel,
                  setSelectionModel: setSelectionModel,
                  fetchData: fetchData,
                }),
            }}
            rowsPerPageOptions={[5, 10, 25]}
            rows={rows}
            rowCount={rowCount}
            columns={columns}
            pageSize={take}
            pagination
            paginationMode="server"
            onPageSizeChange={(newPageSize) => {
              setTake(newPageSize);
            }}
            onPageChange={(newPage) => {
              setSkip(newPage * take);
            }}
            sortingMode="server"
            sortModel={sort}
            onSortModelChange={handleSortModelChange}
            checkboxSelection
            isRowSelectable={(params) => {
              return params.row.id !== 0; // * cannot remove user with id = 0, assume to be the superuser
            }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            loading={isLoading}
            autoHeight
          />
        </Paper>
      </Card>
    </React.Fragment>
  );
}

export default UserListPage;
