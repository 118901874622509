import styled from "styled-components/macro";
import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Grid,
  Link,
  LinearProgress,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { useAxios } from "../../utils/useAxios";
import { useEffect, useState } from "react";
import { DndContainer } from "../../components/dnd/Container";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import { EditFAQValues } from "./FAQ.type";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);

const EditFAQSequencePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [items, setItems] = useState<any[]>([]);

  const {
    fetch: submit,
    isLoading: isSubmitting,
    isSuccess: isSubmitSuccess,
    isError: isSubmitError,
    error: submitError,
  } = useDelayedAxios(
    "PATCH",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/faq/content/order-sequence`
  );

  const handleSubmit = async (data: EditFAQValues) => {
    await submit({ data });
  };

  const { response, isLoading, isError, error } = useAxios(
    "GET",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/faq/content?status=1`
  );

  const columns = [
    {
      field: "titleEn",
      headerName: t("page.faq.form.detailEn"),
    },
  ];

  useEffect(() => {
    const rows = response?.[0] ?? [];
    const items: Record<string, any>[] = rows.map((row) => {
      return {
        id: row.id,
        titleTc: row.titleTc,
        titleEn: row.titleEn,
        status: row.status ? t("component.on") : t("component.off"),
      };
    });
    setItems(items);
  }, [response]);

  // Redirect when update success
  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/faq"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("component.editSequence")} />
      <Grid item md={2}>
        <Typography
          variant="h3"
          gutterBottom
          display="inline"
          sx={{ whiteSpace: "nowrap" }}
        >
          {t("component.editSequence")}
        </Typography>
      </Grid>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/faq">
          {t("page.faq.title")}
        </Link>
        <Typography>{t("component.editSequence")}</Typography>
      </Breadcrumbs>
      <Divider my={2} />

      {isSubmitSuccess ? (
        <Card mb={6}>
          <CardContent>
            <Alert severity="success" my={3}>
              {t("form.success.updateSequence")}
            </Alert>
          </CardContent>
        </Card>
      ) : isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Alert severity="warning" my={3}>
          {error}
        </Alert>
      ) : isSubmitError ? (
        <Alert severity="warning" my={3}>
          {submitError}
        </Alert>
      ) : (
        <Grid my={5}>
          <Card mb={6}>
            <CardContent>
              <DndContainer
                cardList={items}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                columns={columns}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </>
  );
};

export default EditFAQSequencePage;
