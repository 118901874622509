import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  Checkbox,
  FormControlLabel,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import CopyRight from "../CopyRight";
import { useNetwork } from "../../hooks/useNetwork";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function SignIn() {
  const isOnline = useNetwork();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const { t } = useTranslation();
  const rememberMe = localStorage.getItem("rememberMe");
  const loginData: {
    email: string;
    password: string;
  } = rememberMe ? JSON.parse(rememberMe) : {};

  return (
    <Formik
      initialValues={{
        email: loginData.email ? loginData.email : "",
        password: loginData.password ? loginData.password : "",
        submit: false,
        rememberMe: !!rememberMe,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Must be a valid email"))
          .max(255)
          .required(t("Email is required")),
        password: Yup.string().max(255).required(t("Password is required")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await signIn(values.email, values.password, values.rememberMe);

          navigate("/");
        } catch (error: any) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: t(message) });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {isOnline && errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {!isOnline && (
            <Alert mt={2} mb={3} severity="warning">
              Please Connect to the Network
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label={t("Email address")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label={t("Password")}
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />

          <FormControlLabel
            label={t("page.auth.form.rememberMe")}
            control={
              <Checkbox
                name="rememberMe"
                checked={values.rememberMe}
                onChange={(e) => {
                  setFieldValue("rememberMe", e.target.checked);
                }}
              />
            }
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ my: 3 }}
          >
            {t("Login")}
          </Button>

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            sx={{ my: 3 }}
            href={"/admin/reset-password"}
          >
            {t("Forgot password")}
          </Button>
          <CopyRight />
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
