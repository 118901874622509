import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import SwitchWrapper from "../../components/form/SwitchWrapper";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
interface ButtonProps extends SpacingProps {
  component?: string;
}
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

export type EditUserFormValues = {
  id?: number;
  name: string;
  email: string;
  userRoleId: number;
  password: string;
  confirmPassword: string;
  status: number;
  createdAt?: string;
  updatedAt?: string;
};

type EditUserFormProps = {
  initialValues: EditUserFormValues;
  handleSubmit: (values: EditUserFormValues) => Promise<void>;
  submitError?: string;
  userRoles: any[];
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Must be a valid email").required("Required"),
  userRoleId: Yup.number().min(-0, "Required").required("Required"),
  password: Yup.mixed().when("id", {
    is: (val: any) => typeof val !== "undefined" && val !== null,
    then: Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255)
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
        "Must has at least 1 capital letter, 1 lower case letter and 1 number"
      ),
    otherwise: Yup.string()
      .min(8, "Must be at least 8 characters")
      .max(255)
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/,
        "Must has at least 1 capital letter, 1 lower case letter and 1 number"
      )
      .required("Required"),
  }),
  confirmPassword: Yup.string().when("password", {
    is: (val: any) => val && val.length > 0,
    then: Yup.string()
      .oneOf([Yup.ref("password")], "Both password need to be the same")
      .required("Required"),
    otherwise: Yup.string().nullable(),
  }),
  status: Yup.number().required("Required"),
});

export function EditUserForm({
  initialValues,
  handleSubmit,
  submitError,
  userRoles,
}: EditUserFormProps) {
  const { t } = useTranslation();
  const isUpdate =
    typeof initialValues?.id !== "undefined" && initialValues?.id !== null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t("User Info")}
            </Typography>
            <form onSubmit={handleSubmit}>
              {/* Form */}
              <Grid item xs={12} sm={2}>
                <SwitchWrapper name="status" label={t("form.status")} />
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextFieldWrapper
                    name="email"
                    label={t("page.user.form.email")}
                    type="email"
                    disabled={isUpdate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldWrapper
                    name="name"
                    label={t("page.user.form.name")}
                    type="name"
                    // disabled={isUpdate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextFieldWrapper
                    name="userRoleId"
                    label={t("page.user.form.userRole")}
                    select // tell TextField to render select
                  >
                    <MenuItem key={0} value={-1} disabled>
                      -- {t("Please Select")} --
                    </MenuItem>

                    {userRoles?.map((userRole: any) => (
                      <MenuItem value={userRole.id} key={userRole.id}>
                        {userRole.name}
                      </MenuItem>
                    )) ?? (
                      <MenuItem value={""} disabled>
                        Loading...
                      </MenuItem>
                    )}
                  </TextFieldWrapper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextFieldWrapper
                    name="password"
                    label={t("Password")}
                    type="password"
                  />
                  {isUpdate && (
                    <FormHelperText>
                      {t(
                        "Please leave blank if password does not need to be updated"
                      )}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextFieldWrapper
                    name="confirmPassword"
                    label={t("Confirm password")}
                    type="password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mr={1}
                  >
                    {isUpdate ? t("form.save") : t("form.create")}
                  </LoadingButton>
                  <CancelButtonWithDialog backUrl="/accounts" />
                </Grid>
              </Grid>

              {submitError && (
                <Alert mt={2} mb={3} severity="warning">
                  {submitError}
                </Alert>
              )}
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default EditUserForm;
