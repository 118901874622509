import { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Link,
  Typography,
} from "@mui/material";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import BranchForm, { EditBranchValues } from "./BranchForm";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);

// Form Component
function CreateBranchPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/branch`
  );

  const handleSubmit = async (values: EditBranchValues) => {
    let data: EditBranchValues = { ...values };
    await submit({ data });
  };

  // Redirect when create success
  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/branch-management"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("component.create")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("component.create")}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/branch-management">
          {t("page.branch.storeInfo")}
        </Link>
        <Typography>{t("component.create")}</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      {isSubmitSuccess ? (
        <Card mb={6}>
          <CardContent>
            <Alert severity="success" my={3}>
              {t("form.success.create")}
            </Alert>
          </CardContent>
        </Card>
      ) : (
        <BranchForm handleSubmit={handleSubmit} submitError={submitError} />
      )}
    </>
  );
}

export default CreateBranchPage;
