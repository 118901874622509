import {
  Button,
  ButtonProps,
  Card as MuiCard,
  CardHeader,
  Divider as MuiDivider,
  CardContent as MuiCardContent,
  Grid,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import _ from "lodash";
import { spacing } from "@mui/system";
import React, { Fragment, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EditTierValues, OfferDetail } from "../EditMemberTierForm";
import { initialOfferDetail } from "../CreateMemberTier";
import TextFieldWrapper from "../../../components/form/TextFieldWrapper";
import { useFormikContext } from "formik";
import MemberTierDetailItemAddDrop from "./MemberTierDetailItemAddDrop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { CKEditorWrapper } from "../../../components/form/CKEditorWrapper";
import RadioButtonWrapper from "../../../components/form/RadioButtonWrapper";
import {
  MemberTierDetailIconList,
  MemberTierDetailTypeEnum,
} from "../memberTier.enum";
import SwitchWrapper from "../../../components/form/SwitchWrapper";
import { MEMBER_TIER_TOOLBAR_CONFIG } from "../const";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

type MemberTierAddDropFormProps = {
  name: string;
  atLeastOne?: boolean;
  offersName: string;
};

function MemberTierDetailAddDrop({
  name,
  atLeastOne = false,
  offersName,
}: MemberTierAddDropFormProps) {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<EditTierValues>();

  const addDetail = useCallback(() => {
    const clonedList = _.cloneDeep(values[name] || []);
    clonedList.push(initialOfferDetail);
    setFieldValue(name, clonedList);
  }, [initialOfferDetail, setFieldValue, values]);

  const removeDetail = useCallback(
    (index) => {
      if (values[name].length === 0) return;
      if (atLeastOne && values[name].length === 1) return;
      const clonedList = _.cloneDeep(values[name]);
      clonedList.splice(index, 1);
      setFieldValue(name, clonedList);
    },
    [setFieldValue, atLeastOne, values]
  );

  return (
    <>
      {values[name] &&
        values[name].map((value, index) => {
          return (
            <Card key={index} mb={6}>
              <CardContent style={{ backgroundColor: "#F7F9FC" }}>
                <Grid container key={index} alignItems="start" spacing={2}>
                  <Grid item xs={12}>
                    <SwitchWrapper
                      name={`${name}[${index}].tierClass`}
                      label={t("page.memberTier.form.tierClass")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name={`${name}[${index}].subtitleTc`}
                      label={t(`page.memberTier.form.${offersName}OfferNameTc`)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                      name={`${name}[${index}].subtitleEn`}
                      label={t(`page.memberTier.form.${offersName}OfferNameEn`)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CKEditorWrapper
                      name={`${name}[${index}].descriptionTc`}
                      label={t(
                        `page.memberTier.form.${offersName}OfferDetailTc`
                      )}
                      data={values[name][index].descriptionTc ?? ""}
                      setFieldValue={setFieldValue}
                      config={{
                        toolbar: MEMBER_TIER_TOOLBAR_CONFIG,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CKEditorWrapper
                      name={`${name}[${index}].descriptionEn`}
                      label={t(
                        `page.memberTier.form.${offersName}OfferDetailEn`
                      )}
                      data={values[name][index].descriptionEn ?? ""}
                      setFieldValue={setFieldValue}
                      config={{
                        toolbar: MEMBER_TIER_TOOLBAR_CONFIG,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="end" spacing={6}>
                      <Grid item xs={12} md={11}>
                        <RadioButtonWrapper<MemberTierDetailTypeEnum>
                          name={`${name}[${index}].iconId`}
                          label={t("page.memberTier.form.offerIcon")}
                          value={values[name][index].iconId}
                          itemList={MemberTierDetailIconList.map(
                            (memberTierDetailIcon) => {
                              const IconComponent = () => {
                                return (
                                  <div
                                    style={{
                                      backgroundColor: "#1E2444",
                                      borderRadius: "50%",
                                      width: "44px",
                                      height: "44px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        memberTierDetailIcon.imagePath
                                      }
                                      alt={memberTierDetailIcon.iconId}
                                    />
                                  </div>
                                );
                              };
                              return {
                                icon: <IconComponent />,
                                value: memberTierDetailIcon.iconId,
                              };
                            }
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} md={1}>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            removeDetail(index);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          );
        })}
      <Grid item xs={1}>
        <Button variant="contained" onClick={addDetail}>
          <AddIcon />
        </Button>
      </Grid>
    </>
  );
}

export default MemberTierDetailAddDrop;
