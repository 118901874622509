import { useFormikContext } from "formik";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditTierValues } from "../EditMemberTierForm";
import MemberTierDetailAddDrop from "./MemberTierDetailAddDrop";
import {
  Button,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent as MuiCardContent,
  Grid,
  styled,
  Typography,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { spacing } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CardContent = styled(MuiCardContent)(spacing);
const Card = styled(MuiCard)(spacing);

function MemberTierDetailForm() {
  const { t } = useTranslation();

  const list = [
    {
      title: t("page.memberTier.form.welcomeOffers"),
      name: "welcomeOffersDetail",
      atLeastOne: false,
      offersName: "welcomeOffers",
    },
    {
      title: t("page.memberTier.form.birthdayMonthOffers"),
      name: "birthdayMonthOffersDetail",
      atLeastOne: false,
      offersName: "birthdayMonthOffers",
    },
    {
      title: t("page.memberTier.form.allYearRoundOffers"),
      name: "allYearRoundOffersDetail",
      atLeastOne: false,
      offersName: "allYearRoundOffers",
    },
    {
      title: t("page.memberTier.form.exclusiveOffers"),
      name: "exclusiveOffersDetail",
      atLeastOne: false,
      offersName: "exclusiveOffers",
    },
    {
      title: t("page.memberTier.form.monthlyExclusiveOffers"),
      name: "monthlyExclusiveOffersDetail",
      atLeastOne: false,
      offersName: "monthlyExclusiveOffers",
    },
  ];

  return (
    <>
      {list.map((item, index) => (
        <Card mb={6} key={index}>
          <CardContent style={{ padding: 0 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <CardHeader title={item.title} />
              </AccordionSummary>
              <AccordionDetails>
                <MemberTierDetailAddDrop
                  name={item.name}
                  atLeastOne={item.atLeastOne}
                  offersName={item.offersName}
                />
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      ))}
    </>
  );
}

export default MemberTierDetailForm;
