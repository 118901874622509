import {
  Typography,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent as MuiCardContent,
  styled,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import SettingFormIk from "./SettingFormIk";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);

export interface SettingPageForm {
  androidAppVersion: string;
  iosAppVersion: string;
  maintenanceMode: number;
  forceUpdateMode: number;
}

const SettingPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("General Setting")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("page.app.title")}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Typography>{t("page.app.title")}</Typography>
      </Breadcrumbs>
      <Divider my={2} />

      <SettingFormIk />
    </>
  );
};

export default SettingPage;
