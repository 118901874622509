import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  CircularProgress,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { useAxioses } from "../../utils/useAxios";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import EditUserForm, { EditUserFormValues } from "./EditUserForm";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Alert = styled(MuiAlert)(spacing);

// Form function
function CreateUserPage() {
  const { userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    response: initResponse,
    isError: isInitError,
    isLoading: isInitLoading,
    error: initError,
  } = useAxioses([
    {
      method: "GET",
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/user-roles?take=0&skip=0`,
    },
    {
      method: "GET",
      url: `${process.env.REACT_APP_USER_SERVICE_URL}/users/${userId}`,
    },
  ]);
  const userRoles = initResponse?.[0]?.[0];
  const userValues = useMemo(() => {
    if (initResponse) {
      const userValues = initResponse?.[1];
      userValues.confirmPassword = "";
      return userValues;
    }
  }, [initResponse]);

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "PATCH",
    `${process.env.REACT_APP_USER_SERVICE_URL}/users/${userId}`
  );

  //Submit button
  const handleSubmit = async (values: EditUserFormValues) => {
    // Remove unnecessary data from values

    const body: Record<string, unknown> = {
      name: values.name,
      userRoleId: values.userRoleId,
      password: values.password,
      status: values.status,
    };

    if (body.password === "") {
      delete body.password;
    }

    submit({ data: body });
  };

  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => navigate("/accounts"),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  return (
    <>
      <Helmet title={t("Update User Information")} />
      <Typography variant="h3" gutterBottom display="inline">
        {t("Update User Information")}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          {t("page.home.title")}
        </Link>
        <Link component={NavLink} to="/accounts">
          {t("Accounts")}
        </Link>
        <Typography>{t("Update User Information")}</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      {isInitLoading ? (
        <Grid item>
          <CircularProgress />
        </Grid>
      ) : isInitError ? (
        <Alert mt={2} mb={3} severity="warning">
          {initError}
        </Alert>
      ) : isSubmitSuccess ? (
        <Alert severity="success" my={3}>
          {t("Updated User Information Successfully")}
        </Alert>
      ) : (
        <EditUserForm
          initialValues={userValues ?? ({} as any)}
          handleSubmit={handleSubmit}
          userRoles={userRoles}
          submitError={submitError}
        />
      )}
    </>
  );
}

export default CreateUserPage;
