import React, { useState } from "react";
import styled from "styled-components/macro";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Grid,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)<{ m?: number }>(spacing);

interface SearchBarProps {
  columns: any;
  where: Record<string, string | number | undefined>;
  setWhere: React.Dispatch<
    React.SetStateAction<Record<string, string | number | undefined>>
  >;
  fetchData: () => void;
}

function PopUpBannerSearchBar({ setWhere }: SearchBarProps) {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState("");

  const handleClick = (e: any) => {
    setWhere({ keyword });
  };

  const handleSelectNews = (e: any) => {
    setKeyword(e.target.value);
  };

  return (
    <React.Fragment>
      <Card mb={4}>
        <CardContent sx={{ "&:last-child": { pb: 4 } }}>
          <Typography variant="h6" gutterBottom>
            {t("form.keywords")}
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleClick(e);
            }}
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  value={keyword}
                  size="small"
                  m={1}
                  label={t("page.pop-up-banner.form.keyword")}
                  fullWidth
                  variant="outlined"
                  name="keyword"
                  onChange={handleSelectNews}
                ></TextField>
              </Grid>

              <Grid item xs={12}>
                <Button
                  mx={1}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {t("Search")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default PopUpBannerSearchBar;
