export enum LinkType {
  NONE = "NONE",
  URI = "URI",
  DEEP_LINK = "DEEP_LINK",
}

export enum DeepLinkType {
  PROFILE = "PROFILE",
  QR_CODE = "QR_CODE",
  MEMBER_TIER = "MEMBER_TIER",
  BRANCH = "BRANCH",
  REWARD = "REWARD",
  FAQ = "FAQ",
  NEWS_ID = "NEWS_ID",
  INBOX_PERSONAL = "INBOX_PERSONAL",
  INBOX_PROMOTIONAL = "INBOX_PROMOTIONAL",
  ORDERING_SITE = "ORDERING_SITE",
}

export const DeepLinkIdList = [DeepLinkType.NEWS_ID];
