import React from "react";
import { Ability } from "@casl/ability";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

import { SidebarItemsType } from "../../types/sidebar";
import reduceChildRoutes from "./reduceChildRoutes";

type SidebarNavListProps = {
  depth: number;
  pages: SidebarItemsType[];
  permission: string;
};

const SidebarNavList: React.FC<SidebarNavListProps> = (props) => {
  const { pages, depth, permission } = props;
  const { userAbility } = useAuth();
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute,
        depth,
        permission,
        userAbility: userAbility ?? new Ability(),
      }),
    [] as JSX.Element[]
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
