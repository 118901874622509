// react component
import { useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import createTheme from "./theme";
import useTheme from "./hooks/useTheme";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// language
import "./i18n";

// routing
import routes from "./routes";

// auth
import { AuthProvider } from "./contexts/JWTContext";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

function App() {
  const content = useRoutes(routes, { basename: "/admin" });
  const { theme } = useTheme();

  console.log(process.env.REACT_APP_CM_TESTING);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={"%s | " + process.env.REACT_APP_APP_NAME}
        defaultTitle={process.env.REACT_APP_APP_NAME}
      />
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <DndProvider backend={HTML5Backend}>
                  <AuthProvider>{content}</AuthProvider>
                </DndProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );
}

export default App;
