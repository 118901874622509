import axios from "../utils/axios";

const url = String(process.env.REACT_APP_AUTHORIZATION_SERVICE_URL || "");

async function login(email: string, password: string) {
  const response = await axios.post(url + "/login", {
    email,
    password,
  });

  return response;
}

async function validateToken(token: string) {
  const response = await axios.post(url + "/cms-validate-token", {
    token,
  });

  return response;
}

export default { login, validateToken };
