import { Ability } from "@casl/ability";
import { Can } from "@casl/react";
import {
  Alert as MuiAlert,
  Button,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import {
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { spacing, SpacingProps } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { NavLink } from "react-router-dom";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useDelayedAxios } from "../../utils/useDelayedAxios";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

const Alert = styled(MuiAlert)(spacing);

type GrisToolbarProps = {
  subject: string; //For Ability Checking
  field: string; ////For Ability Checking
  enableExport?: boolean;
  enableCreate?: boolean;
  createLink?: string;
  createText?: string;
  enableDelete?: boolean;
  deleteApi?: string;
  deleteSuccessText?: string;
  enableEditSequence?: boolean;
  editSequenceLink?: string;
  editSequenceText?: string;
  selectionModel?: GridSelectionModel;
  setSelectionModel?: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  fetchData?: () => void;
};

export default function GridToolbar({
  subject,
  field,
  enableExport,
  enableCreate,
  enableDelete,
  createLink,
  deleteApi,
  createText,
  enableEditSequence,
  editSequenceLink,
  editSequenceText,
  selectionModel,
  setSelectionModel,
  fetchData,
  deleteSuccessText,
}: GrisToolbarProps) {
  const { userAbility } = useAuth();
  const { t } = useTranslation();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const {
    fetch: submitDelete,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
    error: deleteError,
  } = useDelayedAxios("POST", deleteApi);

  const handleClickOpen = () => {
    setDeleteDialogOpen(true);
  };

  const handleClose = useCallback(() => {
    if (isDeleteSuccess && fetchData) {
      setSelectionModel && setSelectionModel([]);
      fetchData();
    }
    setDeleteDialogOpen(false);
  }, [fetchData, isDeleteSuccess]);

  // Close Dialog when delete success
  useEffect(() => {
    if (isDeleteSuccess) {
      const timer = setTimeout(() => {
        handleClose();
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    isDeleteSuccess,
    deleteDialogOpen,
    setDeleteDialogOpen,
    fetchData,
    handleClose,
  ]);

  return (
    <GridToolbarContainer>
      {/* Create button */}
      {enableCreate && createLink && (
        <Can
          do="create"
          on={subject}
          field={field}
          ability={(userAbility as any) ?? new Ability()}
        >
          <Button
            variant="text"
            size="small"
            color="primary"
            component={NavLink}
            to={createLink}
          >
            <AddIcon />
            {createText}
          </Button>
        </Can>
      )}

      {enableEditSequence && editSequenceLink && (
        <Can
          do="updateAll"
          on={subject}
          field={field}
          ability={(userAbility as any) ?? new Ability()}
        >
          <Button
            variant="text"
            size="small"
            color="primary"
            component={NavLink}
            to={editSequenceLink}
          >
            <EditIcon />
            {editSequenceText}
          </Button>
        </Can>
      )}

      {/* Delete button & dialog */}
      {enableDelete &&
        deleteApi &&
        selectionModel &&
        deleteSuccessText &&
        fetchData && (
          <>
            <Can
              do="deleteAll"
              on={subject}
              field={field}
              ability={(userAbility as any) ?? new Ability()}
            >
              <Button
                variant="text"
                size="small"
                color="error"
                disabled={selectionModel.length === 0}
                onClick={handleClickOpen}
              >
                <DeleteIcon />
                {t("Delete")}
              </Button>
            </Can>
            {/* Confirm Delete Dialog */}
            <Dialog
              open={deleteDialogOpen}
              onClose={handleClose}
              fullWidth
              aria-labelledby="delete-alert-dialog-title"
              aria-describedby="delete-alert-dialog-description"
            >
              {isDeleteSuccess ? (
                <DialogContent>
                  <Alert severity="success" my={3}>
                    {deleteSuccessText}
                  </Alert>
                </DialogContent>
              ) : isDeleteError ? (
                <DialogContent>
                  <Alert mt={2} mb={3} severity="warning">
                    {deleteError}
                  </Alert>
                </DialogContent>
              ) : (
                <>
                  <DialogTitle id="delete-alert-dialog-title">
                    {t("Are you sure?")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="delete-alert-dialog-description">
                      {t(
                        "Are you sure to delete? This process cannot be undone."
                      )}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      {t("No")}
                    </Button>

                    <LoadingButton
                      onClick={() => {
                        submitDelete({ data: { id: selectionModel } });
                      }}
                      loading={isDeleteLoading}
                      color="error"
                      mr={1}
                    >
                      {t("Yes")}
                    </LoadingButton>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </>
        )}

      {/* Export button */}
      {enableExport && (
        <Can
          do="exportAll"
          on={subject}
          field={field}
          ability={(userAbility as any) ?? new Ability()}
        >
          <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        </Can>
      )}
    </GridToolbarContainer>
  );
}
