import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing, SpacingProps } from "@mui/system";
import { useTranslation } from "react-i18next";

import {
  Alert as MuiAlert,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardHeader,
  Grid,
  MenuItem,
} from "@mui/material";

import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import CancelButtonWithDialog from "../../../components/form/CancelButtonWithDialog";
import TextFieldWrapper from "../../../components/form/TextFieldWrapper";
import { GeneralSection, TncSection } from "../../../types/general";
import { CKEditorWrapper } from "../../../components/form/CKEditorWrapper";
import { GENERAL_TOOLBAR_CONFIG } from "../const";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);
const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

export type EditRegistrationTncValues = {
  id?: number;
  tncContentTc: string;
  tncContentEn: string;
  section: TncSection;
};

type EditRegistrationTncFormProps = {
  initialValues: EditRegistrationTncValues;
  handleSubmit: (values: EditRegistrationTncValues) => Promise<void>;
  submitError?: string;
};

const validationSchema = Yup.object().shape({
  tncContentTc: Yup.string().required("Required"),
  tncContentEn: Yup.string().required("Required"),
});

export function EditRegistrationTncForm({
  initialValues,
  handleSubmit,
  submitError,
}: EditRegistrationTncFormProps) {
  const { t } = useTranslation();
  const isUpdate =
    typeof initialValues?.id !== "undefined" && initialValues?.id !== null;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit, setFieldValue, values }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <CardHeader title={t("page.general.tnc.title.registration")} />
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <CKEditorWrapper
                      name="tncContentTc"
                      label={t("page.general.form.tncTc")}
                      data={values.tncContentTc ?? ""}
                      setFieldValue={setFieldValue}
                      config={{
                        toolbar: GENERAL_TOOLBAR_CONFIG,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CKEditorWrapper
                      name="tncContentEn"
                      label={t("page.general.form.tncEn")}
                      data={values.tncContentEn ?? ""}
                      setFieldValue={setFieldValue}
                      config={{
                        toolbar: GENERAL_TOOLBAR_CONFIG,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {submitError && (
              <Alert mt={2} mb={3} severity="warning">
                {submitError}
              </Alert>
            )}

            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              mr={1}
            >
              {isUpdate ? t("Save") : t("Create")}
            </LoadingButton>
            <CancelButtonWithDialog backUrl="/general-management" />
          </form>
        </>
      )}
    </Formik>
  );
}

export default EditRegistrationTncForm;
