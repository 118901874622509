import React from "react";
import styled from "styled-components";

function HomePage() {
  return <Container>Welcome to Cova Admin</Container>;
}

export default HomePage;

const Container = styled.div`
  font-size: 36px;
  font-weight: bold;
  display: fledx;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
