import { matchPath } from "react-router-dom";

import { SidebarItemsType } from "../../types/sidebar";
import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
import { Ability } from "@casl/ability";
import { Can } from "@casl/react";

type ReduceChildRoutesProps = {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[];
  currentRoute: string;
  userAbility: Ability;
  permission: string;
};

const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const { items, page, depth, currentRoute, userAbility, permission } = props;

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    items.push(
      <Can
        do="readAll"
        on={permission}
        field={page.permissionField}
        ability={userAbility as any}
        key={page.title}
      >
        <SidebarNavListItem
          depth={depth}
          icon={page.icon}
          key={page.title}
          badge={page.badge}
          open={!!open}
          title={page.title}
          href={page.href}
        >
          <SidebarNavList
            depth={depth + 1}
            pages={page.children}
            permission={permission}
          />
        </SidebarNavListItem>
      </Can>
    );
  } else {
    items.push(
      <Can
        do="readAll"
        on={permission}
        field={page.permissionField}
        ability={userAbility as any}
        key={page.title}
      >
        <SidebarNavListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          badge={page.badge}
          title={page.title}
        />
      </Can>
    );
  }

  return items;
};

export default reduceChildRoutes;
