import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { SettingPageForm } from "./SettingPage";
import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  FormControl,
  FormGroup,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import styled from "styled-components/macro";
import { spacing, SpacingProps } from "@mui/system";
import { LoadingButton as MuiLoadingButton } from "@mui/lab";
import { Formik } from "formik";
import CancelButtonWithDialog from "../../components/form/CancelButtonWithDialog";
import { useAxioses } from "../../utils/useAxios";
import { MainteranceSwitcher } from "./MainteranceSwitcher";
import { useEffect, useState } from "react";
import { useDelayedAxios } from "../../utils/useDelayedAxios";
import TextFieldWrapper from "../../components/form/TextFieldWrapper";
import React from "react";
import ConfirmDialog from "./components/ConfirmDialog";
import SwitchWrapper from "../../components/form/SwitchWrapper";
import { Can } from "@casl/react";
import { Ability } from "@casl/ability";
import useAuth from "../../hooks/useAuth";
import { formSubmitNavigationDelayTime } from "../../config/const";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Alert = styled(MuiAlert)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}

const initialValues: SettingPageForm = {
  androidAppVersion: "",
  iosAppVersion: "",
  maintenanceMode: 0,
  forceUpdateMode: 0,
};

const LoadingButton = styled(MuiLoadingButton)<ButtonProps>(spacing);

const SettingFormIk = () => {
  const { userAbility } = useAuth();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    response,
    isLoading: isInitLoading,
    isError: isInitError,
    error: initError,
  } = useAxioses([
    {
      url: `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/app-version/findLatest`,
      method: "POST",
    },
  ]);

  const appVersionData = response?.[0]?.data;

  const {
    fetch: submit,
    error: submitError,
    isSuccess: isSubmitSuccess,
  } = useDelayedAxios(
    "POST",
    `${process.env.REACT_APP_CONTENT_SERVICE_URL}/cms/app-version`
  );

  const validationSchema = Yup.object().shape({
    androidAppVersion: Yup.string().required(t("Required")),
    iosAppVersion: Yup.string().required(t("Required")),
  });

  const handleSubmit = async (values: SettingPageForm) => {
    setOpen(true);
  };

  // Redirect when create success
  useEffect(() => {
    if (isSubmitSuccess) {
      const timer = setTimeout(
        () => window.location.reload(),
        formSubmitNavigationDelayTime
      );
      return () => {
        clearTimeout(timer);
      };
    }
  }, [navigate, isSubmitSuccess]);

  if (isSubmitSuccess) {
    return (
      <Card mb={6}>
        <CardContent>
          <Alert severity="success" my={3}>
            {t("form.success.update")}
          </Alert>
        </CardContent>
      </Card>
    );
  }

  if (isInitLoading) {
    return <LinearProgress />;
  }

  if (isInitError) {
    return (
      <Alert severity="warning" my={3}>
        {initError}
      </Alert>
    );
  }

  return (
    <>
      <Formik
        initialValues={appVersionData ?? initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          isSubmitting,
          touched,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card mb={6}>
                <CardContent>
                  <Grid item xs={12} md={6} lg={4}>
                    <SwitchWrapper
                      name="maintenanceMode"
                      label={t("page.app.form.maintenanceMode")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <SwitchWrapper
                      name="forceUpdateMode"
                      label={t("page.app.form.forceUpdateMode")}
                    />
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextFieldWrapper
                        name="androidAppVersion"
                        label={t("page.app.form.androidAppVersion")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      <TextFieldWrapper
                        name="iosAppVersion"
                        label={t("page.app.form.iosAppVersion")}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              {submitError && (
                <Alert mt={2} mb={3} severity="warning">
                  {submitError}
                </Alert>
              )}

              <Grid item xs={12}>
                <Can
                  do="updateAll"
                  on="CmsAppVersionManagement"
                  field="AppVersionSetting"
                  ability={(userAbility as any) ?? new Ability()}
                >
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mr={1}
                  >
                    {t("Save")}
                  </LoadingButton>
                  {/* <CancelButtonWithDialog backUrl="/" /> */}
                </Can>
              </Grid>
            </form>
            <ConfirmDialog
              content={t(
                `Are you sure to switch ${
                  values.maintenanceMode === 1 ? "on" : "off"
                } maintenance mode?`
              )}
              open={open}
              handleClose={() => {
                setOpen(false);
              }}
              handleConfirm={async () => {
                await submit({
                  data: {
                    androidAppVersion: values.androidAppVersion,
                    iosAppVersion: values.iosAppVersion,
                    forceUpdateMode: values.forceUpdateMode,
                    maintenanceMode: values.maintenanceMode,
                  },
                });
                setOpen(false);
              }}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default SettingFormIk;
