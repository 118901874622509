import { useState, useEffect, useMemo } from "react";
import axios from "./axios";

export type HTTPMethod =
  | "DELETE"
  | "GET"
  | "HEAD"
  | "OPTIONS"
  | "PATCH"
  | "POST"
  | "PUT";

export type useAxiosStatus = "IDLE" | "LOADING" | "SUCCESS" | "ERROR";

interface QueryDetail {
  method: HTTPMethod;
  url: string;
  data?: Record<string, unknown> | FormData;
}

export function useAxios(
  method: HTTPMethod,
  url: string,
  data?: Record<string, unknown> | FormData
) {
  const [status, setStatus] = useState<useAxiosStatus>("IDLE");
  const isIdle = useMemo(() => status === "IDLE", [status]);
  const isLoading = useMemo(() => status === "LOADING", [status]);
  const isSuccess = useMemo(() => status === "SUCCESS", [status]);
  const isError = useMemo(() => status === "ERROR", [status]);

  const [response, setResponse] = useState<any>(undefined);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setStatus("LOADING");

      try {
        const result = await axios({ method, url, data });
        setResponse(result.data);
        setStatus("SUCCESS");
      } catch (err: any) {
        setStatus("ERROR");
        if (err.message) {
          setError(err.message.toString());
        } else {
          setError(err);
        }
      }
    };

    fetchData();
  }, []);

  return { status, isIdle, isLoading, isSuccess, isError, response, error };
}

export function useAxioses(queryDetails: QueryDetail[]) {
  const [status, setStatus] = useState<useAxiosStatus>("IDLE");
  const isIdle = useMemo(() => status === "IDLE", [status]);
  const isLoading = useMemo(() => status === "LOADING", [status]);
  const isSuccess = useMemo(() => status === "SUCCESS", [status]);
  const isError = useMemo(() => status === "ERROR", [status]);

  const [response, setResponse] = useState<any>();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setStatus("LOADING");

      try {
        const results = await Promise.all(
          queryDetails.map((queryDetail) => {
            return axios({ ...queryDetail });
          })
        );

        setResponse(results.map((result) => result.data));
        setStatus("SUCCESS");
      } catch (err: any) {
        setStatus("ERROR");
        if (err.message) {
          setError(err.message.toString());
        } else {
          setError(err);
        }
      }
    };
    fetchData();
  }, []);

  return { status, isIdle, isLoading, isSuccess, isError, response, error };
}
