import * as React from "react";

import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import { Tooltip, Button, Typography } from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

function NavbarUserDropdown() {
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { t } = useTranslation();

  const handleSignOut = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Tooltip title={t("Logout") as string}>
        <Button
          aria-owns={"menu-appbar"}
          aria-haspopup="true"
          onClick={handleSignOut}
          color="inherit"
          size="large"
        >
          <Power />
          <Typography ml={1}>{t("Logout")}</Typography>
        </Button>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
