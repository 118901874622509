import React from "react";
import { Field, FieldProps } from "formik";
import { TextField as MuiTextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { TextFieldProps } from "material-ui";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

type TextFieldWrapperProps = {
  name: string;
  label: string;
  select?: boolean;
  multiline?: boolean;
  my?: number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
} & TextFieldProps &
  Record<string, any>;

const TextFieldWrapper: React.FunctionComponent<TextFieldWrapperProps> = ({
  name,
  label,
  variant,
  size,
  fullWidth,
  select,
  multiline,
  my,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <TextField
            {...field}
            label={t(label)}
            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched && t(meta.error ?? "")}
            variant={variant ?? "outlined"}
            size={size ?? "small"}
            fullWidth={fullWidth === undefined ? true : fullWidth}
            my={my === undefined ? 2 : my}
            onChange={onChange ?? field.onChange}
            {...props}
            select={select}
            multiline={multiline}
          >
            {props.children}
          </TextField>
        );
      }}
    </Field>
  );
};

export default TextFieldWrapper;
