import React, { useRef } from "react";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormHelperText,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import styled from "styled-components/macro";
import { awsConfig } from "../../config";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const Button = styled(MuiButton)<ButtonProps>(spacing);

type ImageFieldProps = {
  fieldName: string;
  header: string;
  buttonText: string;
  remarks?: string[];
  isUpdate?: boolean;
};

// Thumbnail Field with Preview
export function ImageField({
  fieldName,
  header,
  buttonText,
  remarks,
  isUpdate,
}: ImageFieldProps) {
  const { t } = useTranslation();
  const { values, errors, touched, setFieldValue } = useFormikContext<any>();
  const urlFieldName = fieldName + "Url";
  const isModifiedFieldName =
    "is" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1) + "Modified";
  const inputRef = useRef<HTMLInputElement>(null);

  // Clear the images preview in memory
  useEffect(() => {
    return () => {
      if (values[urlFieldName]) {
        URL.revokeObjectURL(values[urlFieldName]);
      }
    };
  });

  return (
    <>
      {/* Header */}
      <Typography
        variant="subtitle2"
        gutterBottom
        color={errors[fieldName] && touched[fieldName] ? "#d32f2f" : ""}
      >
        {`${t(header)}:`}
      </Typography>

      <label htmlFor={fieldName}>
        <input
          ref={inputRef}
          id={fieldName}
          name={fieldName}
          hidden
          accept=".png, .jpg"
          type="file"
          onChange={(event: any) => {
            setFieldValue(isModifiedFieldName, true);
            if (event.currentTarget.files[0]) {
              setFieldValue(
                fieldName,
                event.currentTarget.files[0],
                false //Formik update internal values state asynchronously and will cause yup validate base on previous value
              );
              setFieldValue(
                urlFieldName,
                URL.createObjectURL(event.currentTarget.files[0]),
                false
              );
            }
          }}
        />
        {/* Upload Buttom  */}
        {!values[fieldName] && (
          <Button variant="contained" component="span" size="small">
            {t(buttonText)}
          </Button>
        )}
      </label>

      {/* Preview */}
      {values[fieldName] && (
        <ImageListItem sx={{ width: "100%" }}>
          <img
            src={
              isUpdate && !values[isModifiedFieldName]
                ? `${awsConfig.s3Url}/public/img/${values[fieldName]}`
                : values[urlFieldName]
            }
            alt={values[fieldName].name ?? values[fieldName]}
            loading="lazy"
            style={{ maxHeight: "300px", objectFit: "scale-down" }}
          />
          <ImageListItemBar
            title={values[fieldName].name ?? values[fieldName]}
            actionIcon={
              // Remove Button
              <IconButton
                sx={{
                  color: "rgba(255, 255, 255, 0.54)",
                }}
                aria-label={`Delete ${values[fieldName]}`}
                onClick={() => {
                  if (inputRef && inputRef.current) {
                    inputRef.current.value = "";
                  }
                  setFieldValue(fieldName, null);
                  URL.revokeObjectURL(values[urlFieldName] ?? "");
                  setFieldValue(urlFieldName, null);
                  setFieldValue(isModifiedFieldName, true);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      )}

      {/* Error Message */}
      <FormHelperText error>
        {touched[fieldName] && t(errors[fieldName] as string)}
      </FormHelperText>

      {/* Caption */}
      {remarks && (
        <Typography variant="caption">
          {remarks.map((line, index, array) => (
            <React.Fragment key={index}>
              {line}

              {index !== array.length - 1 ? <br /> : ""}
            </React.Fragment>
          ))}
        </Typography>
      )}
    </>
  );
}
